import { FC, useState } from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import { Campaign } from "../../api/queries";
import { TableCell } from "./TableCell";
import { StatusCell } from "./StatusCell";
import { SlotsList } from "../SlotsList";

const TableRowRoot = styled("tr")({
  "td:first-of-type": {
    paddingLeft: "32px",
    paddingRight: "32px",
  },
  "td:last-of-type": {
    paddingLeft: "32px",
    paddingRight: "32px",
  },
});

const Image = styled("img")({
  width: 100,
  aspectRatio: "4 / 3",
  objectFit: "contain",
});

const ImageCell = styled(TableCell)({
  paddingTop: 8,
  paddingBottom: 8,
  display: "flex",
  justifyContent: "center",
});

const StyledLink = styled(Link)({
  color: "#263238",
  textDecoration: "underline",
});

const SlotText = styled.span({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const Icon = styled.i({
  cursor: "pointer",
});

const SlotsContainer = styled.div({
  marginTop: 4,
  paddingTop: 4,
  borderTop: "1px solid #263238",
});

type TableRowProps = {
  campaign: Campaign;
};

const currencyFormat = new Intl.NumberFormat(navigator.language, {
  style: "currency",
  currency: "EUR",
});

export const TableRow: FC<TableRowProps> = ({
  campaign: {
    price,
    days,
    fromDate,
    imgSrc,
    name,
    reservedAt,
    slots,
    status,
    toDate,
    id,
    city,
    paymentType,
    isPaid,
  },
}) => {
  const time = `${reservedAt
    .getHours()
    .toString()
    .padStart(2, "0")}.${reservedAt.getMinutes().toString().padStart(2, "0")}`;
  const [openSlots, setOpenSlots] = useState(false);

  return (
    <TableRowRoot>
      <ImageCell variant="td">
        <Image loading="lazy" src={imgSrc} alt={name} />
      </ImageCell>
      <TableCell variant="td" textAlign="start">
        {city.name}
      </TableCell>
      <TableCell variant="td" textAlign="start">
        {days} giorni
        <br />
        {fromDate.toLocaleDateString()} - {toDate.toLocaleDateString()}
      </TableCell>
      <TableCell variant="td">
        <StyledLink to={`/campaign/${id}`}>{name}</StyledLink>
      </TableCell>
      <TableCell variant="td">
        <SlotText>
          {slots.length}
          <Icon
            onClick={() => setOpenSlots((curr) => !curr)}
            className="material-symbols-outlined"
          >
            {!openSlots ? "expand_more" : "expand_less"}
          </Icon>
        </SlotText>
        {openSlots && (
          <SlotsContainer>
            <SlotsList slots={slots} />
          </SlotsContainer>
        )}
      </TableCell>
      <TableCell variant="td">
        {reservedAt.toLocaleDateString()}
        <br />
        Ore {time}
      </TableCell>
      <PriceCell
        price={price}
        variant={paymentType === "EXTERNAL" ? "external" : "normal"}
        isPaid={isPaid}
      />
      <StatusCell status={status} />
    </TableRowRoot>
  );
};

type PriceCellProps = {
  variant?: "external" | "normal";
  price: number;
  isPaid?: boolean;
};

const BLUE = "rgb(0, 102, 204)";

const ChipContainer = styled("div")({
  backgroundColor: BLUE,
  borderRadius: 4,
  color: "#fff",
  display: "flex",
  alignItems: "center",
  padding: "4px 8px",
  width: "min-content",
  fontSize: "0.75rem",
  margin: "auto",
  gap: 4,
});

const DoneIcon = styled("i")<{ isPaid?: boolean }>(({ isPaid }) => ({
  borderRadius: "50%",
  border: "1px solid #fff",
  fontSize: "0.75rem",
  ...(isPaid && { background: "white", color: BLUE }),
}));

const Chip = ({ isPaid }: { isPaid?: boolean }) => {
  return (
    <ChipContainer>
      ESTERNO{" "}
      <DoneIcon isPaid={isPaid} className="material-symbols-outlined">
        done
      </DoneIcon>
    </ChipContainer>
  );
};

const PriceCell: FC<PriceCellProps> = ({
  price,
  variant = "normal",
  isPaid,
}) => {
  return (
    <TableCell
      style={{
        ...(variant === "external" && { color: BLUE }),
      }}
      variant="td"
    >
      {currencyFormat.format(price)}
      {variant === "external" && <Chip isPaid={isPaid} />}
    </TableCell>
  );
};
