import { FC, lazy } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { Stack, Alert, Text } from "@alexpireddu/map-ui";
import styled from "@emotion/styled";
import { SPIDReactButton } from "@dej611/spid-react-button";
import "@dej611/spid-react-button/dist/index.css";

import { useAuthenticated } from "../../hooks";
import { MainPage } from "../../components";
import spidlogo from "../../assets/spid-logo-c-lb.svg";
import agidlogo from "../../assets/spid-agid-logo-lb.png";
import spid_errors from "../../assets/spid_errors.json";
import { ENV_VARIABLES } from "../../infrastructure/environments";

const UserLoginForm = lazy(() => import("./UserLoginForm"));

const Spid = styled.img({
  height: 52,
  marginTop: 8,
});
const Agid = styled.img({
  height: 25,
  alignSelf: "center",
  display: "inline-flex",
});
const StyledStack = styled(Stack)({
  button: {
    margin: 0,
  },
});

const SpidButtonContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  "li:nth-last-of-type(1)": {
    display: "none",
  },
  "li:nth-last-of-type(2)": {
    display: "none",
  },
  'a[href="#"]': {
    outline: "none",
  },
});

type SpidErrorKey = keyof typeof spid_errors;

const isSpidError = (value: unknown): value is SpidErrorKey => {
  if (spid_errors[value as SpidErrorKey]) {
    return true;
  }

  return false;
}

const Login: FC = () => {
  const data = useAuthenticated().data!;
  const [searchParams] = useSearchParams();
  const spid_error = searchParams.get("spid-error");

  if (data.auth) {
    return <Navigate to="/" />;
  }

  return (
    <MainPage variant="main" hasFindOutMore>
      <StyledStack
        spacing={3}
        paddingTop="0.25rem"
        paddingBottom="0.25rem"
        paddingLeft="3rem"
        paddingRight="3rem"
      >
        <Spid alt="spid" src={spidlogo} />
        {(ENV_VARIABLES.REACT_APP_USE_SPID === "false" ||
          !ENV_VARIABLES.REACT_APP_USE_SPID) && (
            <UserLoginForm />
          )}
        {process.env.NODE_ENV === "development" &&
          ENV_VARIABLES.REACT_APP_USE_SPID === "true" && (
            <SpidButtonContainer>
              <SPIDReactButton
                url={ENV_VARIABLES.REACT_APP_SPID_URL!}
                extraProviders={[
                  {
                    entityName: "Publista Test",
                    entityID: "https://demo.spid.gov.it",
                  },
                  {
                    entityName: "AGID Test",
                    entityID: "https://validator.spid.gov.it",
                  },
                ]}
                supported={[
                  "https://demo.spid.gov.it",
                  "https://validator.spid.gov.it",
                ]}
                type="dropdown"
                fluid
              />
            </SpidButtonContainer>
          )}
        {process.env.NODE_ENV === "production" &&
          ENV_VARIABLES.REACT_APP_USE_SPID === "true" && (
            <SpidButtonContainer>
              <SPIDReactButton
                url={ENV_VARIABLES.REACT_APP_SPID_URL!}
                type="dropdown"
                fluid
              />
            </SpidButtonContainer>
          )}
        {spid_error && <AlertSpidError error={spid_error} />}
        <Agid alt="agid" src={agidlogo} />
      </StyledStack>
    </MainPage>
  );
};

type AlertSpidErrorProps = {
  error: SpidErrorKey | string;
};

const AlertSpidError: FC<AlertSpidErrorProps> = ({ error }) => {
  const error_text = isSpidError(error)
    ? spid_errors[error]
    : "Qualcosa è andato storto, riprova più tardi o contatta il supporto.";

  return (
    <Alert variant="error">
      <Text fontWeight="bold">Si è verificato un errore</Text>
      <Text fontSize="14px" lineHeight={1.3} maxWidth={470}>
        ({error}) {error_text}
      </Text>
    </Alert>
  );
}

export default Login;
