import {
  FC,
  PropsWithChildren,
  ChangeEventHandler,
  ComponentPropsWithoutRef,
  FocusEventHandler,
  ComponentProps,
} from "react";
import { Input, Label, Select, Stack, Text } from "@alexpireddu/map-ui";
import styled from "@emotion/styled";

type TextProps = ComponentProps<typeof Text>;

type StyledFormControlProps = {
  fullWidth?: boolean;
};

const StyledFormControl = styled("div")<StyledFormControlProps>(
  ({ fullWidth }) => ({
    ...(fullWidth && {
      width: "100%",
    }),
    display: "flex",
    flexDirection: "column",
    gap: 4,
    "& label": {
      margin: 0,
      fontSize: "0.875rem",
      lineHeight: 1.5,
    },
    "& input": {
      padding: "8px 16px",
      fontSize: "0.875rem",
      height: 35,
    },
    "& select": {
      padding: "8px 16px",
      fontSize: "0.875rem",
      height: 35,
    },
  })
);

const StyledSelect = styled(Select)<{ error?: boolean }>(({ error }) => ({
  ...(error && {
    div: {
      borderColor: "#be0000",
    },
    svg: {
      color: "#be0000",
    },
  }),
}));

const StyledInput = styled(Input, {
  shouldForwardProp: (propName) => propName !== "error",
})<{ error?: boolean }>(({ error }) => ({
  ...(error && { borderColor: "#be0000", color: "#be0000" }),
}));

type SelectProps = ComponentPropsWithoutRef<typeof Select>;

type SelectChangeEventHandler = SelectProps["onChange"];

export type TextFieldProps = PropsWithChildren<
  StyledFormControlProps & {
    label?: string;
    value?: string | number;
    options?: SelectProps["options"];
    placeholder?: string;
    helperText?: string;
    helperTextProps?: TextProps;
    errorText?: string;
    errorTextProps?: TextProps;
    error?: boolean;
    onBlur?: FocusEventHandler<HTMLInputElement>;
    className?: string;
    isSearchable?: boolean;
    isDisabled?: boolean
  } & (
      | {
          select?: false;
          onChange?: ChangeEventHandler<HTMLInputElement>;
        }
      | {
          select: true;
          onChange?: SelectChangeEventHandler;
        }
    )
>;

const noop = () => void 0;

const TextField: FC<TextFieldProps> = ({
  select,
  label,
  children,
  value,
  onChange = noop,
  options,
  placeholder,
  helperText,
  helperTextProps = {},
  errorText,
  errorTextProps = {},
  error,
  onBlur,
  className,
  isSearchable,
  isDisabled,
  ...rest
}) => {
  const InputElement = select ? (
    <StyledSelect
      error={error}
      size="xs"
      value={value}
      onChange={onChange as SelectChangeEventHandler}
      options={options}
      placeholder={placeholder}
      onBlur={onBlur}
      isSearchable={isSearchable}
      isDisabled={isDisabled}
    />
  ) : (
    <StyledInput
      error={error}
      onBlur={onBlur}
      value={value}
      onChange={onChange as ChangeEventHandler<HTMLInputElement>}
      placeholder={placeholder}
    />
  );

  return (
    <StyledFormControl className={className} {...rest}>
      {label && <Label isInvalid={error}>{label}</Label>}
      {InputElement}
      {(errorText || helperText) && (
        <Stack direction="row">
          {errorText && (
            <Text
              padding="0 16px"
              color="#be0000"
              textAlign="left"
              fontSize={12}
              {...errorTextProps}
            >
              {errorText}
            </Text>
          )}
          {helperText && (
            <Text
              marginLeft="auto"
              padding="0 16px"
              color="grey"
              textAlign="right"
              fontSize={12}
              {...helperTextProps}
            >
              {helperText}
            </Text>
          )}
        </Stack>
      )}
    </StyledFormControl>
  );
};

export default TextField;
