import { FC } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuthenticated } from "../../hooks";

const Authenticated: FC = () => {
  const data = useAuthenticated().data!;
  if (!data.auth) {
    return <Navigate to="login" />;
  }

  return <Outlet />;
};


export default Authenticated