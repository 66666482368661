import { z } from "zod";
import { Slot } from "../queries";
import { ENV_VARIABLES } from "../../infrastructure/environments";

const formatSlot = (slot: Slot) => {
  const { from_date, to_date } = slot;

  return {
    ...slot,
    from_date: from_date.toISOString().split('T')[0],
    to_date: to_date.toISOString().split('T')[0],
  };
};

const formatBody = (body: CreateCampaignBody | PutCampaignBody) =>
  Object.entries(body)
    .filter((el) => el[1] !== undefined && el[1] !== null)
    .reduce((acc, el) => {
      if (typeof el[1] === 'string' || el[1] instanceof File) {
        acc.append(el[0], el[1]);
      } else {
        el[1].forEach((slot) =>
          acc.append(el[0], JSON.stringify(formatSlot(slot)))
        );
      }
      return acc;
    }, new FormData());

export type CreateCampaignBody = {
  name: string;
  slots: Slot[];
};

export type CreateCampaignRequest = {
  city_id: string;
  body: CreateCampaignBody;
};

export const createCampaign = ({ city_id, body }: CreateCampaignRequest) => fetch(
    `${ENV_VARIABLES.REACT_APP_BASE_PATH}/consumer/city/${city_id}/video/campaign`,
    {
      method: 'POST',
      body: formatBody(body),
    }
  );

export type PutCampaignBody = {
  name?: string;
  image?: File;
  slots?: Slot[];
  campaign_id: string;
};

export type PutCampaignRequest = {
  body: PutCampaignBody;
};

export const putCampaign = ({ body }: PutCampaignRequest) => fetch(`${ENV_VARIABLES.REACT_APP_BASE_PATH}/consumer/video/campaign`, {
    method: "PUT",
    body: formatBody(body),
  });

const NON_EMPTY_MSG = 'Il campo non può essere vuoto';

/**
 * This func is just a wrapper to create a new non-empty string schema on each call so that we avoid using a single shared schema instance
 */
const nonempty = () =>
  z.string({ required_error: NON_EMPTY_MSG }).trim().nonempty(NON_EMPTY_MSG);

export const BillingSchema = z.object({
  address: nonempty(),
  city: nonempty(),
  fiscal_code: nonempty().regex(
    /(^[A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST][0-9LMNPQRSTUV]{2}[A-Z][0-9LMNPQRSTUV]{3}[A-Z]$)|(^[0-9]{11}$)/,
    'Codice fiscale non valido'
  ),
  name: nonempty(),
  postal_code: nonempty().regex(/^[0-9]{5}$/, 'CAP non valido'),
  province: nonempty(),
  vat_number: nonempty().regex(
    /(^[0-9]{11}$)|(^(IT|it)[0-9]{11}$)/,
    'P.IVA non valida'
  ),
  pec: z.string().email('PEC non valida').optional().or(z.literal('')),
  sdi_code: z
    .string()
    .regex(/^([A-z]|[0-9]){7}$/, 'Codice destinatario non valido')
    .optional()
    .or(z.literal('')),
});

export type Billing = z.TypeOf<typeof BillingSchema>;

export type PayCampaignRequest = {
  campaign_id: string;
  billing?: Billing;
};

export const payCampaign = async (bodyRequest: PayCampaignRequest) => {
  const response = await fetch(
    `${ENV_VARIABLES.REACT_APP_BASE_PATH}/consumer/video/campaign/pay`,
    {
      method: 'POST',
      body: JSON.stringify(bodyRequest),
      redirect: 'follow',
    }
  );

  const body = await response.json();

  if (body && body.redirect_url) {
    window.location.href = body.redirect_url;
  } else if (response.redirected) {
    window.location.href = response.url;
  }

  return response;
};

export const planCampaign = async (campaign_id: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_PATH}/consumer/video/campaign/plan`,
    {
      method: 'POST',
      body: JSON.stringify({
        campaign_id,
      }),
    }
  );

  return response;
};
