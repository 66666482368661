import { CampaignState } from "../api/queries";

export const statusOfState = (state: CampaignState) => {
  switch (state) {
    case CampaignState.APPROVED: {
      return "Approvata";
    }
    case CampaignState.COMPLETED: {
      return "Conclusa";
    }
    case CampaignState.CREATED: {
      return "Creata";
    }
    case CampaignState.ERR_PAY: {
      return "Insoluta";
    }
    case CampaignState.IN_PROGRESS: {
      return "Attiva";
    }
    case CampaignState.TO_VERIFY: {
      return "In verifica";
    }
    case CampaignState.REJECTED: {
      return "Rifiutata";
    }
  }
}
