import { z } from "zod";
import { ENV_VARIABLES } from "../../infrastructure/environments";

const FILE_TYPE_ACCEPTED = ["image/jpeg", "image/png"];

const MAX_SIZE = 1024 * 1024;

const ImageUpload = z
  .instanceof(File)
  .refine((file) => FILE_TYPE_ACCEPTED.includes(file.type))
  .refine((file) => file.size <= MAX_SIZE);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ImageUpload = z.TypeOf<typeof ImageUpload>;

export const patchCampaignImage = async ({
  campaignId,
  image,
}: {
  campaignId: string;
  image: ImageUpload;
}) => {
  const formData = new FormData();
  formData.append("image", image);

  await fetch(`${ENV_VARIABLES.REACT_APP_BASE_PATH}/consumer/video/campaign/${campaignId}/uploadimage`, {
    method: "PATCH",
    body: formData,
  });
}
