import { CSSProperties, FC, useEffect, useState } from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import styled from '@emotion/styled';
import { Text } from '@alexpireddu/map-ui';

import { Bullet } from '../';
import uploadImage from '../../assets/uploadImage.svg';

const Clock = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="70%"
      width="100%"
      viewBox="0 0 48 48"
    >
      <path
        fill="#93989a"
        d="m31.35 33.65 2.25-2.25-7.95-8V13.35h-3V24.6ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24t1.575-7.75q1.575-3.65 4.3-6.375 2.725-2.725 6.375-4.3Q19.9 4 24 4t7.75 1.575q3.65 1.575 6.375 4.3 2.725 2.725 4.3 6.375Q44 19.9 44 24t-1.575 7.75q-1.575 3.65-4.3 6.375-2.725 2.725-6.375 4.3Q28.1 44 24 44Zm0-20Zm0 17q7 0 12-5t5-12q0-7-5-12T24 7q-7 0-12 5T7 24q0 7 5 12t12 5Z"
      />
    </svg>
  );
};

const UploadRoot = styled('div')<{
  hasImage?: boolean;
  aspectRatio?: CSSProperties['aspectRatio'];
}>(({ hasImage, aspectRatio = '16 / 9' }) => ({
  width: '100%',
  display: 'flex',
  aspectRatio,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  backgroundColor: '#cfd8dc',
  cursor: 'pointer',
  gap: 12,
  ...(hasImage && {
    backgroundColor: '#000000',
  }),
}));
const Image = styled('img')<{
  aspectRatio?: CSSProperties['aspectRatio'];
}>(({ aspectRatio = '16 / 9' }) => ({
  position: 'absolute',
  maxWidth: '100%',
  width: '100%',
  aspectRatio,
  objectFit: 'contain',
}));
const StyledBullet = styled(Bullet)({
  margin: '0 4px',
  backgroundColor: '#607d8b',
});
const Add = styled.img({
  width: 70,
});
const RemoveButton = styled.button({
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  position: 'absolute',
  right: 16,
  top: 16,
  color: 'white',
  cursor: 'pointer',
  zIndex: 9,
  width: 36,
  height: 36,
});

const Container = styled.div({
  width: '100%',
  borderRadius: 12,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
});

const Footer = styled.div({
  flex: 1,
  backgroundColor: '#81868a',
  display: 'flex',
  alignItems: 'center',
  aspectRatio: '16 / 3',
});

const Skeletons = styled.div({ flex: 4, height: '100%' });
const SkeletonContainer = styled.div<{
  height: string;
  gap?: number;
  borderTop?: string;
}>(({ height, gap, borderTop }) => ({
  flex: 2,
  height,
  gap,
  borderTop,
  display: 'flex',
  alignItems: 'center',
  padding: '0 32px',
}));
const Skeleton = styled.div<{
  height: number;
  rounded?: boolean;
  flex: number;
}>(({ height, rounded, flex }) => ({
  height,
  ...(rounded && {
    borderRadius: height / 2,
  }),
  flex,
  backgroundColor: '#93989a',
}));

const ClockContainer = styled.div({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  borderLeft: '2px solid #93989a',
  flex: 1,
});

const Icon = styled.i({
  fontVariationSettings: '"FILL" 0, "wght" 600, "GRAD" 0, "opsz" 48',
});

const AspectRatio = styled.span({
  backgroundColor: '#606369',
  color: 'white',
  borderRadius: 4,
  padding: '0 3px',
  fontWeight: 'bold',
  fontSize: '0.825rem',
});

export type ImageUploadProps = Pick<DropzoneOptions, 'onDropAccepted'> & {
  file?: File;
};

const ImageUpload: FC<ImageUploadProps> = ({ onDropAccepted, file }) => {
  const aspectRatio = '16 / 9';
  const [URI, setURI] = useState('');
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    onDropAccepted: onDropAccepted,
  });

  useEffect(() => {
    if (file) {
      setURI(URL.createObjectURL(file));
    }

    return () => {
      setURI((last) => {
        URL.revokeObjectURL(last);
        return '';
      });
    };
  }, [file]);

  return (
    <Container>
      <UploadRoot
        hasImage={Boolean(URI)}
        aspectRatio={aspectRatio}
        {...getRootProps()}
      >
        {!URI && (
          <>
            <input {...getInputProps()} />
            <Text as="h3" textAlign="center">
              Carica o trascina qui il tuo contenuto
            </Text>
            <Text
              textAlign="center"
              display="flex"
              alignItems="center"
              color="#607d8b"
              marginBottom={12}
            >
              Immagine JPEG o PNG <StyledBullet /> Dimensione massima 5 MB{' '}
              <StyledBullet />
              <span>
                Formato <AspectRatio>16:9</AspectRatio>
              </span>
            </Text>
            <Add src={uploadImage} alt="add" />
          </>
        )}
        {URI && (
          <>
            <RemoveButton
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onDropAccepted && onDropAccepted([], e.nativeEvent);
              }}
            >
              <Icon className="material-symbols-outlined">close</Icon>
            </RemoveButton>
            <Image
              src={URI}
              aspectRatio={aspectRatio}
              alt="preview of uploaded file"
            />
          </>
        )}
      </UploadRoot>
      <Footer>
        <Skeletons>
          <SkeletonContainer height="60%">
            <Skeleton height={20} rounded flex={1} />
          </SkeletonContainer>
          <SkeletonContainer
            height="40%"
            gap={16}
            borderTop="2px solid #93989a"
          >
            <Skeleton height={15} flex={1} />
            <Skeleton height={8} flex={2} />
          </SkeletonContainer>
        </Skeletons>
        <ClockContainer>
          <Clock />
        </ClockContainer>
      </Footer>
    </Container>
  );
};

export default ImageUpload;
