import { forwardRef } from 'react';
import { Text, Stack } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';

import graphic_guide_01 from '../../assets/graphic_guide_01.jpg';
import graphic_guide_02 from '../../assets/graphic_guide_02.jpg';
import graphic_guide_03 from '../../assets/graphic_guide_03.jpg';
import graphic_guide_04 from '../../assets/graphic_guide_04.jpg';
import graphic_guide_05 from '../../assets/graphic_guide_05.jpg';

type SVGImageProps = { width?: string };

const SVGImage = styled.img<SVGImageProps>(({ width = '60%' }) => ({
  width,
  alignSelf: 'center',
}));

const Bold = styled.span({
  fontWeight: 'bold',
});

export const GraphicGuideContent = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <Stack ref={ref} spacing={5}>
      <Text as="h3" fontWeight="bold">
        GUIDA GRAFICA
      </Text>
      <Stack alignItems="center" spacing={4}>
        <Text>
          <Bold>IMMAGINE IN 16:9</Bold>
          <br />
          Carica immagini in rapporto di dimensione 16:9. Se caricherai immagini
          con rapporti diversi, il tuo contenuto verrà trasmesso con fasce di
          compensazione nere, ma sarebbe un peccato perché perderesti spazio
          utile.
        </Text>
        <SVGImage src={graphic_guide_05} width="80%" />
      </Stack>
      <Stack alignItems="center" spacing={4}>
        <Text>
          <Bold>NO SFONDO BIANCO</Bold>
          <br />
          Per non infastidire chi legge il tuo messaggio evita di usare sfondi
          bianchi, poiché creano un effetto abbagliante. Come alternativa usa il
          grigio soft (codice colore: 240-240-240).
        </Text>
        <SVGImage src={graphic_guide_01} width="80%" />
      </Stack>
      <Stack alignItems="center" spacing={4}>
        <Text>
          <Bold>CONTRASTO</Bold>
          <br />
          Per rendere ben leggibile il tuo messaggio assicurati che il colore di
          loghi, testi ed elementi stacchino in modo netto dallo sfondo.
        </Text>
        <SVGImage src={graphic_guide_02} width="80%" />
      </Stack>
      <Stack alignItems="center" spacing={4}>
        <Text>
          <Bold>SCRITTE GRANDI</Bold>
          <br />
          Per rendere ben leggibile il tuo messaggio usa testi di grandi
          dimensioni.
        </Text>
        <SVGImage src={graphic_guide_03} width="80%" />
      </Stack>
      <Stack alignItems="center" spacing={4}>
        <Text>
          <Bold>MESSAGGI SEMPLICI E D'IMPATTO</Bold>
          <br />
          Per sfruttare al meglio i secondi di attenzione dei passanti trasmetti
          una grafica semplice e d'impatto.
        </Text>
        <SVGImage src={graphic_guide_04} width="80%" />
      </Stack>
      <Text fontWeight="bold">
        💡Per creare contenuti efficaci ti consigliamo di rivolgerti a un
        grafico pubblicitario.
      </Text>
      <Text>***********************************</Text>
      <Text fontWeight="bold">INFORMAZIONI AVANZATE</Text>
      <Stack spacing={5}>
        <Stack spacing={2}>
          <Text>CARATTERISTICHE IMMAGINE DA CARICARE</Text>
          <Text>
            <Bold>Dimensione minima:</Bold> 512x288 px (Le immagini di
            dimensioni più grandi verranno ridimensionate automaticamente in
            questa misura)
          </Text>
          <Text>
            <Bold>Formato:</Bold> PNG o JPEG
          </Text>
          <Text>
            <Bold>Peso massimo:</Bold> 5MB
          </Text>
        </Stack>
        <Stack spacing={2}>
          <Text>CARATTERISTICHE SCHERMO</Text>
          <Text>
            <Bold>Dimensione schermo:</Bold> 200x150 cm (di cui 200x113 cm
            riservato alla riproduzione delle immagini e 200x37 cm riservato al
            widget comunale)
          </Text>
          <Text>
            <Bold>Distanza LED:</Bold> 3,9mm
          </Text>
          <Text>******************************************************</Text>
          <Text>
            Per maggiori informazioni o supporto scrivici a{' '}
            <Bold>info@publista.it</Bold>.
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
});
