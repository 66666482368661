import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "@alexpireddu/map-ui";
import { FC } from "react";

export interface LeaveRoutePromptModalProps {
  onConfirm: () => void;
  onClose: () => void;
}

const LeaveRoutePromptModal: FC<LeaveRoutePromptModalProps> = ({ onClose, onConfirm }) => {
  return (
    <Modal>
      <ModalHeader>
        Vuoi uscire?
      </ModalHeader>
      <ModalBody>
        Stai lasciando la prenotazione campagna. <br />
        Ogni informazione non salvata andrà persa. Continuare?
      </ModalBody>
      <ModalFooter>
        <Button variant="secondary" onClick={() => onConfirm()}>Sì, esci</Button>
        <Button variant="primary" onClick={() => onClose()}>No, rimani</Button>
      </ModalFooter>
    </Modal>
  );
}

export default LeaveRoutePromptModal;