import { FC } from "react";
import { sendFeedback } from "../../api/mutations";
import FeedbackControl from "./FeedbackControl";

export type LeaveFeedbackEmbeddedProps = {
    section: string;
    cityId?: string;
    additionalData?: any;
    width?: number | string;
}

const LeaveFeedbackEmbedded: FC<LeaveFeedbackEmbeddedProps> = ({ section, cityId, additionalData, width }) => {
    return (
        <FeedbackControl
            section={section}
            width={width}
            sendFeedbackFn={sendFeedback}
            cityId={cityId}
            additionalData={additionalData}
        />
    );
}

export default LeaveFeedbackEmbedded;