import { FC } from "react";
import { Container, Text, Stack } from "@alexpireddu/map-ui";
import styled from "@emotion/styled";

import support from "../../assets/assistenza.png";

const Image = styled("img")({
  maxWidth: 335,
  width: "100%",
  aspectRatio: "1 / 1",
});

const Link = styled("a")({
  fontStyle: "normal",
  color: "#0094ca",
  textDecoration: "underline",
});

const Support: FC = () => {
  return (
    <Container maxWidth="md" yGutters disableXGutters>
      <Stack spacing={3} width="100%" alignItems="center">
        <div>
          <Text as="h2" textAlign="center">
            Assistenza
          </Text>
          <Text as="h3" textAlign="center">
            Hai bisogno dell’aiuto di Publista?
          </Text>
        </div>
        <Image src={support} alt="support" />
        <Stack spacing={2} alignItems="center">
          <Text as="h3">Contattaci:</Text>
          <Stack as="address" spacing={1} alignItems="center">
            <Link href="mailto:assistenza@publista.it">
              info@publista.it
            </Link>
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
};

export default Support;
