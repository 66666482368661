/* eslint-disable @typescript-eslint/no-redeclare */
import { z } from "zod";

import { SlotTime } from "./slot";
import { DateSchema } from "./DateSchema";
import { ENV_VARIABLES } from "../../infrastructure/environments";

export const AvailableSlot = z.object({
  slot_time: z.nativeEnum(SlotTime),
  quantity: z.number(),
});

export type AvailableSlot = z.TypeOf<typeof AvailableSlot>;

export const AvailableSlotResponse = z.array(
  z.object({
    from: DateSchema,
    to: DateSchema,
    quantities: z.array(AvailableSlot),
  })
);

export type AvailableSlotResponse = z.TypeOf<typeof AvailableSlotResponse>;

export const getAvailableSlots = async (siteId: string, amount: number = 5) => {
  const result = await (
    await fetch(
      `${ENV_VARIABLES.REACT_APP_BASE_PATH}/consumer/video/site/availableslots?site_id=${siteId}&amount=${amount}`
    )
  ).json();

  return AvailableSlotResponse.parse(result);
}
