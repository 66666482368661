import { Component, PropsWithChildren, FC } from "react";

export type ErrorBoundaryProps = PropsWithChildren<{
  renderError: FC<{ error?: Error }>;
}>;

export type ErrorBoundaryState = {
  hasError: boolean;
  error?: Error;
};

export default class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  render() {
    if (this.state.hasError) {
      const { renderError: ErrorComponent } = this.props;
      return <ErrorComponent error={this.state.error} />;
    }

    return this.props.children;
  }
}
