import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Stack, Text, Button } from "@alexpireddu/map-ui";
import styled from "@emotion/styled";

import completed from "../../assets/completed.png";
import { CampaignCreatedLayout, LeaveFeedbackEmbedded } from "../../components";
import { useCityParams } from "../../hooks";

const Image = styled("img")({
  maxHeight: 340,
  width: "auto",
  objectFit: "contain",
  marginTop: "1rem",
});

const Ol = styled.ol({
  padding: "0 16px",
});

const Li = styled.li({
  display: "list-item",
  listStyle: "auto",
});

const CampaignCreated: FC = () => {
  const navigate = useNavigate();
  const { cityId } = useCityParams();

  return (
    <CampaignCreatedLayout>
      <Stack alignItems="center" spacing={2.5}>
        <Text as="h1" fontSize="2.8rem">
          Campagna prenotata con successo!
        </Text>
        <div>
          <Text lineHeight={1.4}>Ecco i prossimi step:</Text>
          <Ol>
            <Li>
              <Text>
                <Text as="span" fontWeight="bold">
                  Revisioneremo l'immagine caricata
                </Text>{" "}
                per garantire contenuti sicuri e di qualità ai cittadini.
              </Text>
            </Li>
            <Li>
              <Text>
                Riceverai una{" "}
                <Text as="span" fontWeight="bold">
                  email di conferma
                </Text>{" "}
                appena il contenuto sarà approvato.
              </Text>
            </Li>
            <Li>
              <Text>
                La tua{" "}
                <Text as="span" fontWeight="bold">
                  campagna
                </Text>{" "}
                verrà trasmessa nel periodo scelto.
              </Text>
            </Li>
          </Ol>
          <Text>
            Ricordati che se hai bisogno di supporto puoi contattarci in
            qualsiasi momento su{" "}
            <Text as="span" fontWeight="bold">
              info@publista.it
            </Text>
          </Text>
        </div>
        <Stack direction="row" spacing={6}>
          <Image src={completed} alt="campaign creation completed" />
          <Stack direction="column" spacing={1}>
            <LeaveFeedbackEmbedded
              section="CAMPAIGN_SUCCESS_PAYMENT"
              cityId={cityId}
              width={396}
            />
            <Stack flexDirection={"row"} marginTop="1rem" spacing={2}>
              <Button
                variant="secondary"
                onClick={() => navigate("/advertising-history")}
              >
                LE MIE PRENOTAZIONI
              </Button>
              <Button
                variant="accent"
                onClick={() => navigate("/campaign/create")}
              >
                NUOVA PRENOTAZIONE
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </CampaignCreatedLayout>
  );
};

export default CampaignCreated;
