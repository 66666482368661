import { FC } from "react";
import styled from "@emotion/styled";

import { TableCell } from "./TableCell";
import { CampaignState } from "../../api/queries";
import { statusOfState } from "../../utils";
import { Bullet } from "../Bullet";

const statusContainerStyles = {
  [CampaignState.APPROVED]: {
    color: (theme: any) => theme.colors.info,
  },
  [CampaignState.COMPLETED]: {
    color: () => "#363636",
  },
  [CampaignState.CREATED]: {
    color: (theme: any) => theme.colors.accent,
  },
  [CampaignState.ERR_PAY]: {
    color: (theme: any) => theme.colors.error,
  },
  [CampaignState.IN_PROGRESS]: {
    color: (theme: any) => theme.colors.success,
  },
  [CampaignState.TO_VERIFY]: {
    color: (theme: any) => theme.colors.accent,
  },
  [CampaignState.REJECTED]: {
    color: (theme: any) => theme.colors.error,
  }
};

const StatusContainer = styled("span")<StatusCellProps>(
  ({ status, theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    gap: 4,
    span: {
      background: statusContainerStyles[status].color(theme),
    },
  })
);

export type StatusCellProps = {
  status: CampaignState;
};

export const StatusCell: FC<StatusCellProps> = ({ status }) => {
  return (
    <TableCell variant="td" textAlign="end">
      <StatusContainer status={status}>
        <Bullet size={8} /> {statusOfState(status)}
      </StatusContainer>
    </TableCell>
  );
};
