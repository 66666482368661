import { FC, PropsWithChildren, Suspense, useMemo } from "react";
import styled from "@emotion/styled";
import { Container } from "@alexpireddu/map-ui";

import { CreateCampaignHeader, Loading } from "../../components";
import { useLocation } from "react-router-dom";

const Root = styled.div({
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
});

const CreateCampaignLayout: FC<PropsWithChildren> = ({ children }) => {
  const activeStep = useActiveStep();

  return (
    <Root>
      <CreateCampaignHeader
        activeStep={activeStep}
      />
      <Container yGutters maxWidth="lg">
        <Suspense fallback={<Loading />}>{children}</Suspense>
      </Container>
    </Root>
  );
}

const routes = ["general-information", "uploading-image", "payment"];

const useActiveStep = () => {
  const location = useLocation();

  const activeStep = useMemo(() => {
    const lastToken = location.pathname.split("/").pop() || "";

    const idx = routes.indexOf(lastToken);

    return idx !== -1 ? idx : 0;
  }, [location]);

  return activeStep;
}

export default CreateCampaignLayout;
