import { FC, useState } from "react";
import { Container, Text, Stack, Button } from "@alexpireddu/map-ui";
import styled from "@emotion/styled";

import { useCampaigns, useOrder, useStateCount, usePages } from "../../hooks";
import { AdvertisementsHistoryTable } from "../../components";

const TableContainer = styled("div")({
  flex: 1,
  boxShadow: "0 6px 24px 0 rgba(2, 3, 3, 0.16)",
  borderRadius: 12,
});

const StyledContainer = styled(Container)({
  display: "flex",
  flex: 1,
});

const StyledStack = styled(Stack)({
  flex: 1,
});

const AdvertisementsHistory: FC = () => {
  const stateCounts = useStateCount().data!;

  const [order, handleOrderChange] = useOrder();
  const [status, setStatus] = useState(200);
  const { sort_by, sort_mode } = order || {};
  const {
    currentPage,
    pageSize,
    pageSizeRange,
    total,
    onNextPage,
    onPageSizeChange,
    onPrevPage,
  } = usePages(stateCounts.find((s) => s.state === status)!.count);

  const campaigns = useCampaigns({
    sort_by,
    sort_mode: sort_mode === "asc" ? 1 : 2,
    page: currentPage,
    size: pageSize,
    state: status,
  }).data!;

  return (
    <StyledContainer yGutters disableXGutters>
      <StyledStack spacing={3}>
        <Stack direction="row" justifyContent="space-between">
          <Text as="h2">Cronologia prenotazioni</Text>
          <Stack direction="row" spacing={3}>
            <Button
              size="xs"
              variant={status === 200 ? "primary" : "unstyled"}
              onClick={() => setStatus(200)}
            >
              Da verificare
            </Button>
            <Button
              size="xs"
              variant={status === 210 ? "primary" : "unstyled"}
              onClick={() => setStatus(210)}
            >
              Approvata
            </Button>
            <Button
              size="xs"
              variant={status === 220 ? "primary" : "unstyled"}
              onClick={() => setStatus(220)}
            >
              In corso
            </Button>
            <Button
              size="xs"
              variant={status === 230 ? "primary" : "unstyled"}
              onClick={() => setStatus(230)}
            >
              Completata
            </Button>
            <Button
              size="xs"
              variant={status === 240 ? "primary" : "unstyled"}
              onClick={() => setStatus(240)}
            >
              Rifiutata
            </Button>
          </Stack>
        </Stack>
        <TableContainer>
          <AdvertisementsHistoryTable
            campaigns={campaigns}
            orderBy={sort_by}
            direction={sort_mode}
            onOrderChange={handleOrderChange}
            total={total}
            currentPage={currentPage}
            pageSize={pageSize}
            pageSizeRange={pageSizeRange}
            onNextPage={onNextPage}
            onPageSizeChange={onPageSizeChange}
            onPrevPage={onPrevPage}
          />
        </TableContainer>
      </StyledStack>
    </StyledContainer>
  );
};

export default AdvertisementsHistory;
