import { useMutation, useQueryClient } from "@tanstack/react-query";

import { patchCampaignImage } from "../../api/mutations";

export const useImageUpload = () => {
  const queryClient = useQueryClient();
  
  return useMutation(patchCampaignImage, {
    onSuccess: () => {
      queryClient.invalidateQueries(["campaigns"]);
      queryClient.invalidateQueries(["campaign"]);
    },
  });
}
