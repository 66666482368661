import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useRedirectOnMount = (firstStep: string) => {
    const navigate = useNavigate();
    const location = useLocation();
  
    useEffect(() => {
      const lastToken = location.pathname.split("/").pop();
  
      if (lastToken !== firstStep) {
        navigate(firstStep);
      }
  
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  }