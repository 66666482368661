import styled from '@emotion/styled';
import { FC } from 'react';
import { ENV_VARIABLES } from '../../infrastructure/environments';

export interface CityLogoProps {
  cityId: string;
  menu?: boolean;
}

const CityImage = styled.img<{ menu: boolean }>(({ menu }) => ({
  height: menu ? "24px" : '41px',
  width: menu ? "24px" : '41px',
  padding: '0',
  margin: '0',
  objectFit: 'contain',
  borderRadius: '50%',
}));

const CityLogo: FC<CityLogoProps> = ({ cityId, menu = false }) => {
  return (
    <CityImage
      menu={menu}
      alt="Logo comune"
      src={`${ENV_VARIABLES.REACT_APP_BASE_PATH}/consumer/city/${cityId}/logo`}
      title="Logo comune"
    />
  );
};

export default CityLogo;
