import styled from "@emotion/styled";
import { Button } from "@alexpireddu/map-ui";

import { CommandBar, Currency, Badge } from "../../components";
import cart from "../../assets/cart.svg";
import stripe from "../../assets/stripe.jpg";
import { FC } from "react";

const Commands = styled("div")({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

const SVGImage = styled.img({
  width: 20,
  alignSelf: "center",
});

const StripeImage = styled.img({
  marginRight: 24,
});

const NextButton = styled(Button)({
  ":disabled": {
    cursor: "auto",
  },
});

const Spacer = () => {
  return (
    <div
      style={{
        opacity: 0,
      }}
    >
      <CommandBar maxWidth="lg" position="static">
        <Button>Spacer</Button>
      </CommandBar>
    </div>
  );
};

type CommandsBarProps = {
  variant: "general-information" | "uploading-image" | "payment";
  slots: number;
  slotPrice: number;
  onPrev?: () => void;
  onNext: () => void;
  canGoNext: boolean;
};

export const CommandsBar: FC<CommandsBarProps> = ({
  variant,
  slots,
  onPrev,
  onNext,
  slotPrice,
  canGoNext,
}) => {
  return (
    <>
      <Spacer />
      <CommandBar maxWidth="lg">
        <Commands>
          {variant !== "general-information" && (
            <Button variant="secondary" onClick={onPrev}>
              Indietro
            </Button>
          )}
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 8,
            }}
          >
            {variant !== "payment" && Boolean(slots) && (
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "1.25rem",
                  gap: 20,
                  marginRight: 16,
                }}
              >
                <Badge content={slots}>
                  <SVGImage src={cart} alt="carrello" />
                </Badge>
                <Currency color="#363636" value={slots * slotPrice} />
              </span>
            )}
            {variant !== "payment" ? (
              <NextButton
                variant="primary"
                disabled={!canGoNext}
                onClick={onNext}
              >
                Continua
              </NextButton>
            ) : (
              <>
                <StripeImage src={stripe} alt="powered by stripe" />
                <Button
                  variant="primary"
                  disabled={!canGoNext}
                  onClick={onNext}
                >
                  Conferma e paga
                </Button>
              </>
            )}
          </div>
        </Commands>
      </CommandBar>
    </>
  );
}
