import { z } from "zod";

const PaymentTypeSchema = z
.array(z.enum(["STRIPE", "EXTERNAL"]))
.catch(["STRIPE"])
.transform((val) => {
  const hasExternal = val.includes("EXTERNAL");

  return hasExternal ? "BUSINESS" : "CONSUMER";
});

export const getCampaignPaymentType = async () => {
const response = await fetch(
  `${process.env.REACT_APP_BASE_PATH}/consumer/video/campaign/payment-type`,
  {
    method: "GET",
  }
);

return PaymentTypeSchema.parse(await response.json());
};
