/* eslint-disable @typescript-eslint/no-redeclare */
import { z } from "zod";
import { ENV_VARIABLES } from "../../infrastructure/environments";

export const Site = z.object({
    id: z.string(),
    price: z.number(),
    name: z.string(),
    latitude: z.string(),
    longitude: z.string(),
})

export type Site = z.TypeOf<typeof Site>

export const getSite = async (cityId: string) => {
    const response = await (await fetch(`${ENV_VARIABLES.REACT_APP_BASE_PATH}/consumer/city/${cityId}/video/sites/online`)).json()

    return Site.parse(response[0])
}