import { FC } from "react";
import { Text } from "@alexpireddu/map-ui";

const currencyFormat = new Intl.NumberFormat(navigator.language, {
  style: "currency",
  currency: "EUR",
});

export type CurrencyProps = {
  value: number;
  color?: string;
  className?: string;
};

const Currency: FC<CurrencyProps> = ({ value, color, className }) => {
  const parts = currencyFormat.formatToParts(value);
  const integer = parts.find((p) => p.type === "integer")!.value;
  const fraction = parts.find((p) => p.type === "fraction")!.value;
  const currency = parts.find((p) => p.type === "currency")!.value;

  return (
    <span className={className}>
      <Text
        display="inline"
        as="span"
        fontSize="1.4em"
        fontWeight="bold"
        color={color}
      >
        {currency}&nbsp;{integer},
      </Text>
      <Text
        display="inline"
        as="span"
        fontSize="1em"
        fontWeight="bold"
        color={color}
      >
        {fraction}
      </Text>
    </span>
  );
};

export default Currency;
