import { AppBar, AppBarProps, Toolbar, Stack } from "@alexpireddu/map-ui";
import { ReactNode, FC, useState } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";

import { PublistaTitle } from "../PublistaTitle";
import { UserMenu } from "../UserMenu";
import { LeaveFeedbackModal } from "../Feedback";

const StyledToolbar = styled(Toolbar)({
  paddingLeft: 42,
  paddingRight: 42,
});

export type HeaderProps = {
  avatar?: boolean;
  subheader?: ReactNode;
} & AppBarProps;

const Header: FC<HeaderProps> = ({ avatar, subheader, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [feedbackModalOpen, setFeetbackModalOpen] = useState(false);

  return (
    <AppBar {...rest}>
      <StyledToolbar>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Link to="/">
            <PublistaTitle style={{ cursor: "pointer" }} width={150} height={40} fill="#363636" />
          </Link>
          {avatar && (
            <>
              <UserMenu
                open={open}
                onClick={() => setOpen((prev) => !prev)}
                onClickOutside={() => setOpen(false)}
                onOpenFeedbackModal={() => setFeetbackModalOpen(true)}
              />
              <LeaveFeedbackModal
                open={feedbackModalOpen}
                onClose={() => setFeetbackModalOpen(false)}
              />
            </>
          )}
        </Stack>
      </StyledToolbar>
      {subheader && <StyledToolbar variant="dense">{subheader}</StyledToolbar>}
    </AppBar>
  );
};

export default Header;
