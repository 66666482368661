import { FC, Suspense } from "react";
import { RouterProvider } from "react-router-dom";

import { Loading } from "./components";
import { router } from "./routes";

const App: FC = () => (
  <Suspense fallback={<Loading />}>
    <RouterProvider router={router} />
  </Suspense>
);

export default App;
