import { AppBar, Toolbar, Stack } from "@alexpireddu/map-ui";
import { Stepper } from "@alexpireddu/map-ui";
import { Link } from "react-router-dom";
import { FC, useState } from "react";
import styled from "@emotion/styled";

import {
  PublistaTitle,
  UserMenu,
  LeaveFeedbackModal,
} from "../../components";
import { useMediaQuery } from "../../hooks";

const MarginLeft = styled.div({
  marginLeft: "auto",
});

const MainToolbar = styled(Toolbar)(({ variant }) => ({
  ...(variant === "dense" && {
    paddingTop: 12,
    paddingBottom: 12,
  }),
}));

const steps = [
  { label: "Prenotazione campagna" },
  { label: "Carica immagine" },
  { label: "Pagamento" },
];

export const CampaignCreatedHeader: FC = () => {
  const [open, setOpen] = useState(false);
  const [feedbackModalOpen, setFeetbackModalOpen] = useState(false);
  const matches = useMediaQuery("(min-width: 1000px)");

  return (
    <AppBar border top={0} variant="normal" zIndex={10}>
      <MainToolbar variant={matches ? "normal" : "dense"}>
        <Stack direction="row" alignItems="center" width="100%" spacing={6}>
          <Link
            to="/"
            style={{
              height: 40,
            }}
          >
            <PublistaTitle
              style={{ cursor: "pointer" }}
              width={150}
              height={40}
              fill="#363636"
            />
          </Link>
          <div style={{ flex: "1 auto" }}>
            <Stepper steps={steps} activeStep={2} />
          </div>
          <MarginLeft>
            <UserMenu
              open={open}
              onClick={() => setOpen((prev) => !prev)}
              onClickOutside={() => setOpen(false)}
              onOpenFeedbackModal={() => setFeetbackModalOpen(true)}
            />
            <LeaveFeedbackModal
              open={feedbackModalOpen}
              onClose={() => setFeetbackModalOpen(false)}
            />
          </MarginLeft>
        </Stack>
      </MainToolbar>
      {!matches && (
        <Toolbar variant="dense">
          <div style={{ flex: "1 auto", marginBottom: "16px" }}>
            <Stepper steps={steps} activeStep={2} />
          </div>
        </Toolbar>
      )}
    </AppBar>
  );
}
