import { useQuery } from "@tanstack/react-query";

import { getCampaigns, GetCampaignsRequest } from "../../api/queries";

export type CampaignsOrderBy =
  | "days"
  | "name"
  | "slots"
  | "reservedAt"
  | "cost"
  | "status";

export const useCampaigns = ({
  sort_by,
  sort_mode,
  state,
  page,
  size,
}: GetCampaignsRequest) => useQuery(
    ["campaigns", { sort_by, sort_mode, state, page, size }],
    () => {
      return getCampaigns({ sort_by, sort_mode, state, page, size });
    }
  );
