
export const isTestMode = (): boolean =>
  window.location.href.indexOf('qa.publista.it') !== -1;

export const isDevMode = (): boolean =>
  window.location.hostname === 'localhost';

export const isProdMode = (): boolean => !isTestMode() && !isDevMode();

export const ENV_VARIABLES = {
  REACT_APP_X_API_KEY: process.env.REACT_APP_X_API_KEY,
  REACT_APP_BASE_PATH: process.env.REACT_APP_BASE_PATH,
  REACT_APP_USE_MOCKS: process.env.REACT_APP_USE_MOCKS,
  NODE_TLS_REJECT_UNAUTHORIZED: process.env.NODE_TLS_REJECT_UNAUTHORIZED,
  REACT_APP_SPID_URL: process.env.REACT_APP_SPID_URL,
  REACT_APP_USE_SPID: process.env.REACT_APP_USE_SPID,
};
