import { Avatar as MapUIAvatar } from "@alexpireddu/map-ui";
import styled from "@emotion/styled";

export type AvatarProps = {
  size?: "normal" | "large";
};

const sizeAvatar = {
  normal: {
    width: 35,
    height: 35,
    fontSize: "0.875rem",
    lineHeight: 1,
    border: "2px solid #263238",
  },
  large: {
    width: 65,
    height: 65,
    fontSize: "1.375rem",
    border: "3px solid #263238",
  },
};

const Avatar = styled(MapUIAvatar)<AvatarProps>(({ size = "normal" }) => ({
  ...sizeAvatar[size],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#21c5fd",
}));

export default Avatar;
