import { FC, useMemo } from "react";
import { Stack, Text } from "@alexpireddu/map-ui";
import styled from "@emotion/styled";

const Day = styled.div({
  fontWeight: "bold",
  textAlign: "center",
  color: "white",
  backgroundColor: "black",
  borderRadius: 4,
  aspectRatio: "1 / 1",
  width: 30,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const resetTime = (date: Date) => {
  date.setMilliseconds(0);
  date.setSeconds(0);
  date.setMinutes(0);
  date.setHours(0);
  return date;
}

const getDaysDiff = (from: Date, to: Date) => {
  const fromZeroTime = resetTime(new Date(from.valueOf()));
  const toZeroTime = resetTime(new Date(to.valueOf()));
  const distance = toZeroTime.valueOf() - fromZeroTime.valueOf();

  return Math.floor(distance / (1000 * 60 * 60 * 24));
}

const dateFormatter = new Intl.DateTimeFormat("it-IT", {
  month: "short",
  day: "numeric",
  weekday: "short",
  year: "2-digit",
});

const getDayWeekdayMonthParts = (date: Date) => {
  const parts = dateFormatter.formatToParts(date);
  const day = parts.find((el) => el.type === "day")!.value;
  const weekday = parts.find((el) => el.type === "weekday")!.value;
  const month = parts.find((el) => el.type === "month")!.value;
  const year = parts.find((el) => el.type === "year")!.value;

  return [weekday, day, month, year];
}

export type DateListProps = {
  from: Date;
  to: Date;
};

const DateList: FC<DateListProps> = ({ from, to }) => {
  const dateElements = useMemo(() => {
    const fromZeroTime = resetTime(new Date(from.valueOf()));
    const days = getDaysDiff(fromZeroTime, to) + 1;

    return [...new Array(days)].map((_, i) => {
      const date = new Date(fromZeroTime.valueOf() + i * 1000 * 60 * 60 * 24);
      const [weekday, day, month, year] = getDayWeekdayMonthParts(date);

      return (
        <Stack
          key={date.valueOf()}
          direction="column"
          alignItems="center"
          fontSize="0.875rem"
          textTransform="capitalize"
        >
          <Text fontSize="0.875rem" fontWeight="bold" textAlign="center">
            {weekday}
          </Text>
          <Day>{day}</Day>
          <Text
            fontSize="0.875rem"
            textTransform="uppercase"
            textAlign="center"
          >
            {month}
          </Text>
          <Text
            fontSize="0.875rem"
            lineHeight="0.875rem"
            textTransform="uppercase"
            textAlign="center"
          >
            {year}
          </Text>
        </Stack>
      );
    });
  }, [from, to]);
  return (
    <Stack
      direction="row"
      spacing={1}
      paddingTop={16}
      paddingBottom={16}
      paddingLeft={24}
      paddingRight={24}
    >
      {dateElements}
    </Stack>
  );
};

export default DateList;
