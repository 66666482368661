import { Box, Button, Flex } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import icon from './404_ICON.svg';

export interface NotFoundErrorPageProps {
  className?: string;
}

const ErrorPageWrapper = styled.div({
  display: 'inline-block',
  margin: '0 auto',
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '16px',
});

const ErrorPageImage = styled.img({
  fill: '#4F5666',
  color: '#4F5666',
  width: '200px',
  height: '200px',
  transform: 'translateX(50%)',
});

const NotFoundErrorPage: FC<NotFoundErrorPageProps> = ({ className }) => {
  const navigate = useNavigate();

  return (
    <ErrorPageWrapper>
      <ErrorPageImage src={icon} />
      <div style={{ textAlign: 'center', marginTop: '32px' }}>
        <h2>404: La pagina non è stata trovata.</h2>
      </div>
      <Flex mt="32px" justifyContent="center">
        <Box mr="8px">
          <Button variant="secondary" onClick={() => navigate(-1)}>
            Indietro
          </Button>
        </Box>

        <Box ml="8px">
          <Button variant="primary" onClick={() => navigate('/')}>
            Torna alla schermata Home
          </Button>
        </Box>
      </Flex>
    </ErrorPageWrapper>
  );
};

export default NotFoundErrorPage;
