import { z } from "zod";
import { ENV_VARIABLES } from "../../infrastructure/environments";

export const Image = z.object({
  id: z.string(),
});

export const getImage = async (imageId: string) => {
  const response = await fetch(
    `${ENV_VARIABLES.REACT_APP_BASE_PATH}/consumer/image/${imageId}`
  );
  const image = new File([await response.blob()], imageId);

  return image;
}

export const getlastImages = async () => {
  const response = await fetch(
    `${ENV_VARIABLES.REACT_APP_BASE_PATH}/consumer/video/campaigns/lastimages`
  );

  if (!response.ok) {
    return [];
  }

  const images = z.array(Image).parse(await response.json());

  return Promise.all(images.map(({ id }) => getImage(id)));
}