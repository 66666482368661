import { FC, ComponentPropsWithoutRef, PropsWithChildren } from "react";
import { Tooltip as MTooltip } from "@alexpireddu/map-ui";
import styled from "@emotion/styled";

const Container = styled.span({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: 6
});

const Icon = styled.i({
  color: "#b1bec7",
  fontSize: "1rem",
});

type MTooltipProps = ComponentPropsWithoutRef<typeof MTooltip>;

export type TooltipProps = PropsWithChildren<Omit<MTooltipProps, "children">>;

const Tooltip: FC<TooltipProps> = ({ children, ...rest }) => {
  return (
    <MTooltip {...rest} >
      <Container>
        {children}
        <Icon className="filled material-symbols-outlined">info</Icon>
      </Container>
    </MTooltip>
  );
};

export default Tooltip;
