import { FC, useState } from "react";
import { Container, Text, Stack, AppBar, Button } from "@alexpireddu/map-ui";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";

import { ImageUpload, CommandBar, CampaignCard } from "../../components";
import { useAuthenticated, useCampaign, useImageUpload } from "../../hooks";

const UploadButton = styled(Button)({
  marginLeft: "auto",
});

const Padder = () => (
  <AppBar style={{ opacity: 0 }}>
    <Container disableXGutters yGutters maxWidth="md">
      <Button>Carica</Button>
    </Container>
  </AppBar>
);

const CampaignUploadImage: FC = () => {
  const [image, setImage] = useState<File>();

  const { user } = useAuthenticated().data!;

  const { campaignId } = useParams<{ campaignId: string }>();
  const campaign = useCampaign(campaignId!).data!;

  const { mutate, isLoading } = useImageUpload();

  return (
    <Container disableXGutters yGutters maxWidth="md">
      <Stack spacing={4}>
        <Stack spacing={2}>
          <Text as="h2">Informazioni campagna</Text>
          <CampaignCard
            user={user}
            name={campaign.name}
            slots={campaign.slots}
          />
        </Stack>
        <Stack spacing={2}>
          <Text as="h3" fontWeight="bold">
            Caricamento nuova immagine
          </Text>
          <ImageUpload
            file={image}
            onDropAccepted={(files) => {
              setImage(files[0]);
            }}
          />
        </Stack>
      </Stack>
      <Padder />
      <CommandBar>
        <UploadButton
          disabled={!image || isLoading}
          onClick={() => {
            image &&
              mutate({
                campaignId: campaign.id,
                image,
              });
          }}
        >
          Carica
        </UploadButton>
      </CommandBar>
    </Container>
  );
};

export default CampaignUploadImage;
