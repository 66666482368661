import { FC, PropsWithChildren, ComponentProps } from "react";
import { AppBar, AppBarProps, Container } from "@alexpireddu/map-ui";
import styled from "@emotion/styled";

const StyledContainer = styled(Container)({
  paddingTop: 8,
  paddingBottom: 8,
  display: "flex",
  alignItems: "center",
});

export type CommandBarProps = PropsWithChildren<
  Pick<
    AppBarProps,
    "position" | "bottom" | "left" | "right" | "top" | "zIndex"
  > &
    Pick<ComponentProps<typeof Container>, "maxWidth">
>;

const CommandBar: FC<CommandBarProps> = ({
  children,
  position = "fixed",
  bottom = 0,
  left = 0,
  right,
  top,
  zIndex = 100,
  maxWidth = "md",
}) => {
  return (
    <AppBar
      position={position}
      bottom={bottom}
      left={left}
      right={right}
      top={top}
      zIndex={zIndex}
      border
    >
      <StyledContainer maxWidth={maxWidth}>
        {children}
      </StyledContainer>
    </AppBar>
  );
};

export default CommandBar;
