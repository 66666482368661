import { FC, PropsWithChildren } from "react";
import { Container } from "@alexpireddu/map-ui";
import styled from "@emotion/styled";

import { CampaignCreatedHeader } from "./CampaignCreatedHeader";

const Root = styled.div({
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
});

const CampaignCreatedLayout: FC<PropsWithChildren> = ({ children }) => (
  <Root>
    <CampaignCreatedHeader />
    <Container yGutters maxWidth="lg">
      {children}
    </Container>
  </Root>
);

export default CampaignCreatedLayout;
