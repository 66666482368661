import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { MapUiProvider } from "@alexpireddu/map-ui";
import "normalize.css";
import "./fetch-polyfill";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { initializeSentry } from "./infrastructure/sentryConfigurations";
import { ENV_VARIABLES, isDevMode } from "./infrastructure/environments";

if (ENV_VARIABLES.REACT_APP_USE_MOCKS === "true") {
  require("./mocks");
}

initializeSentry();

const client = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={client}>
      <MapUiProvider theme={{}}>
        <App />
      </MapUiProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (isDevMode()) {
  reportWebVitals(console.info);
}