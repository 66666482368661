import { FC, useState } from 'react';
import { Modal, ModalCloseButton, Text, Stack } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';

import FindOutMore1 from '../../assets/findOutMore1.svg';
import FindOutMore2 from '../../assets/findOutMore2.svg';
import FindOutMore3 from '../../assets/findOutMore3.svg';
import FindOutMore4 from '../../assets/findOutMore4.svg';
import FindOutMore5 from '../../assets/findOutMore5.svg';
import FindOutMore6 from '../../assets/findOutMore6.svg';
import Ban from '../../assets/ban.svg';
import noDefamation from '../../assets/noDefamation.svg';
import noOpinions from '../../assets/noOpinions.svg';
import noDrugs from '../../assets/noDrugs.svg';
import noEroticism from '../../assets/noEroticism.svg';
import noRacism from '../../assets/noRacism.svg';
import noViolence from '../../assets/noViolence.svg';

import { GraphicGuideContent } from './GraphicGuideContent';
import { match } from 'ts-pattern';
import { useRef } from 'react';
import { useEffect } from 'react';
import ClickAwayListener from '@mui/base/ClickAwayListener';

const StyledModal = styled(Modal)({
  section: {
    maxWidth: 'none',
    margin: 0,
    height: '90vh',
    maxHeight: '90vh',
    width: 500,
    padding: '2rem',
    overflowY: 'auto',
  },
  alignItems: 'center',
});

type SVGImageProps = { width?: string };

const SVGImage = styled.img<SVGImageProps>(({ width = '60%' }) => ({
  width,
  alignSelf: 'center',
}));

const StyledModalCloseButton = styled(ModalCloseButton)({
  zIndex: 1000,
  aspectRatio: '1 / 1',
});

const Bold = styled.span({
  fontWeight: 'bold',
});

const Italic = styled.span({
  fontStyle: 'italic',
});

const Underline = styled.span({
  textDecoration: 'underline',
});

export type FindOutMoreProps = {
  variant?: 'terms' | 'rules' | 'graphic-guide';
  onClose: () => void;
  open?: boolean;
};

const FindOutMore: FC<FindOutMoreProps> = ({
  variant = 'terms',
  onClose,
  open,
}) => {
  const [openGraphicGuide, setOpenGraphicGuide] = useState(false);
  const graphicGuideRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (openGraphicGuide) {
      graphicGuideRef.current?.scrollIntoView(true);
      window.scrollTo(0, 0);
    }
  }, [openGraphicGuide]);

  const handleOpen = () => {
    document.body.style.overflow = 'hidden';
  };

  const handleClose = () => {
    document.body.style.overflow = '';
    setOpenGraphicGuide(false);
  };

  if (!open) {
    return null;
  }

  const graphicGuide = <GraphicGuideContent ref={graphicGuideRef} />;

  const content = match(openGraphicGuide)
    .with(true, () => graphicGuide)
    .with(false, () =>
      match(variant)
        .with('terms', () => (
          <>
            <Stack spacing={5}>
              <Text as="h3" fontWeight="bold" fontSize="1.5rem">
                PUBBLICHE AFFISSIONI DIGITALI
              </Text>
              <Text>
                Le <Bold>pubbliche affissioni digitali</Bold> sono impianti
                comunali dotati di uno schermo a LED dove aziende, associazioni
                o cittadini possono trasmettere <Bold>propri contenuti</Bold>{' '}
                <Italic>(es. pubblicità, auguri, dediche, annunci)</Italic> a
                fronte del pagamento di una <Bold>tariffa popolare.</Bold>
              </Text>
              <SVGImage src={FindOutMore1} />
              <Text fontWeight="bold">
                Prenotare i propri spazi è semplice e veloce.
              </Text>

              <Stack spacing={3}>
                <Text
                  as="h3"
                  fontWeight="bold"
                  fontSize="1.5rem"
                  textAlign="center"
                >
                  1
                </Text>
                <Text>
                  Vai su <Bold>www.publista.it</Bold> da PC o tablet, accedi con{' '}
                  <Bold>Spid</Bold> e scegli il Comune.
                </Text>
                <SVGImage src={FindOutMore2} width="70%" />
              </Stack>

              <Stack spacing={3}>
                <Text
                  as="h3"
                  fontWeight="bold"
                  fontSize="1.5rem"
                  textAlign="center"
                >
                  2
                </Text>
                <Text>
                  Scegli la <Bold>settimana</Bold> e <Bold>fascia oraria</Bold>{' '}
                  desiderata: <Bold>mattina</Bold>{' '}
                  <Italic>(06:00-12:00)</Italic>, <Bold>pomeriggio</Bold>{' '}
                  <Italic>(12:00-18:00)</Italic>, <Bold>sera</Bold>{' '}
                  <Italic>(18:00-24:00)</Italic>.
                </Text>
                <SVGImage src={FindOutMore3} width="65%" />
              </Stack>
              <Stack spacing={3}>
                <Text
                  as="h3"
                  fontWeight="bold"
                  fontSize="1.5rem"
                  textAlign="center"
                >
                  3
                </Text>
                <Text>
                  Carica l'immagine del contenuto in <Bold>formato png</Bold> o{' '}
                  <Bold>jpeg</Bold> con <Bold>rapporto di dimensione 16:9</Bold>{' '}
                  e <Bold>dimensione massima 5MB</Bold>
                  <Italic>
                    (se l'immagine avrà un rapporto diverso verrà trasmessa con
                    delle fasce di compensazione nere)
                  </Italic>
                  .
                </Text>
                <SVGImage src={FindOutMore4} width="45%" />
              </Stack>
              <Stack spacing={3}>
                <Text
                  as="h3"
                  fontWeight="bold"
                  fontSize="1.5rem"
                  textAlign="center"
                >
                  4
                </Text>
                <Text>
                  Paga la <Bold>tariffa comunale</Bold> (la tariffa varia in
                  base al Comune e alla posizione dell’impianto).
                </Text>
                <SVGImage
                  style={{
                    marginTop: 8,
                  }}
                  src={FindOutMore5}
                />
              </Stack>
              <Stack spacing={3}>
                <Text>
                  Al termine della prenotazione il tuo contenuto verrà trasmesso
                  nel Comune scelto durante il periodo indicato,{' '}
                  <Bold>ogni minuto per 10 secondi</Bold> a rotazione con
                  massimo altri 5 contenuti (di cui 1 comunale).
                </Text>
                <SVGImage src={FindOutMore6} width="70%" />
                <Text fontStyle="italic">
                  ESEMPIO: Se prenoti la tua campagna per la{' '}
                  <Underline>settimana prossima</Underline>, nelle fasce orarie{' '}
                  <Underline>pomeriggio</Underline> e{' '}
                  <Underline>sera</Underline>, significa che il tuo contenuto
                  verrà trasmesso per tutta la settimana prossima dal Lunedì
                  alla Domenica, dalle 12:00 alle 24:00, ogni minuto per 10
                  secondi.
                </Text>
              </Stack>
              <Text>
                ******************************************************
              </Text>
              <Stack spacing={3}>
                <Text as="h3" fontWeight="bold">
                  CONDIZIONI
                </Text>
                <SVGImage src={Ban} width="20%" />
                <Text>
                  Sono vietati i contenuti con riferimenti a:{' '}
                  <Bold>
                    violenza, razzismo, erotismo, droghe, diffamazioni,
                    opinioni.
                  </Bold>
                  <br />
                  <Text as="span" fontWeight="bold" marginTop={10}>
                    Tali contenuti non verranno trasmessi.
                  </Text>
                </Text>
                <Stack
                  direction="row"
                  justifyContent="center"
                  spacing={2.5}
                  marginTop={4}
                  marginBottom={4}
                >
                  <SVGImage width="10%" src={noViolence} />
                  <SVGImage width="10%" src={noRacism} />
                  <SVGImage width="10%" src={noEroticism} />
                  <SVGImage width="10%" src={noDrugs} />
                  <SVGImage width="10%" src={noDefamation} />
                  <SVGImage width="10%" src={noOpinions} />
                </Stack>
                <Text>
                  L’utente è tenuto a prestare attenzione nel caricare immagini
                  conformi alla{' '}
                  <Bold>
                    <Underline
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => setOpenGraphicGuide(true)}
                    >
                      Guida grafica
                    </Underline>
                    . <br />
                  </Bold>
                </Text>
                <Text>
                  ******************************************************
                </Text>
                <Text>
                  Per maggiori informazioni o supporto scrivici a{' '}
                  <Bold>info@publista.it</Bold>.
                </Text>
              </Stack>
            </Stack>
          </>
        ))
        .with('rules', () => (
          <Stack spacing={3}>
            <Text as="h3" fontWeight="bold">
              CONDIZIONI
            </Text>
            <SVGImage src={Ban} width="20%" />
            <Text>
              Sono vietati i contenuti con riferimenti a:{' '}
              <Bold>
                violenza, razzismo, erotismo, droghe, diffamazioni, opinioni.
              </Bold>
              <br />
              <Text as="span" fontWeight="bold" marginTop={10}>
                Tali contenuti non verranno trasmessi.
              </Text>
            </Text>
            <Stack
              direction="row"
              justifyContent="center"
              spacing={2.5}
              marginTop={4}
              marginBottom={4}
            >
              <SVGImage width="10%" src={noViolence} />
              <SVGImage width="10%" src={noRacism} />
              <SVGImage width="10%" src={noEroticism} />
              <SVGImage width="10%" src={noDrugs} />
              <SVGImage width="10%" src={noDefamation} />
              <SVGImage width="10%" src={noOpinions} />
            </Stack>
            <Text>
              L’utente è tenuto a prestare attenzione nel caricare immagini
              conformi alla{' '}
              <Bold>
                <Underline
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => setOpenGraphicGuide(true)}
                >
                  Guida grafica
                </Underline>
                . <br />
              </Bold>
            </Text>
            <Text>******************************************************</Text>
            <Text>
              Per maggiori informazioni o supporto scrivici a{' '}
              <Bold>info@publista.it</Bold>.
            </Text>
          </Stack>
        ))
        .with('graphic-guide', () => graphicGuide)
        .exhaustive()
    )
    .exhaustive();

  return (
    <StyledModal onOpen={handleOpen} onClose={handleClose}>
      <ClickAwayListener onClickAway={onClose}>
        <div>
          <StyledModalCloseButton onClick={onClose} />
          {content}
        </div>
      </ClickAwayListener>
    </StyledModal>
  );
};

export default FindOutMore;
