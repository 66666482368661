import styled from "@emotion/styled";
import { FC } from "react";

const LinkPrivacy = styled.p({
    fontStyle: "normal",
    color: "#025ad5",
    textDecoration: "underline",
    textAlign: "right",
    cursor: "pointer",
});

const Link: FC = () => <LinkPrivacy onClick={() => window.open("https://www.iubenda.com/privacy-policy/419244", "_blank")}>
    Privacy policy
</LinkPrivacy>

export default Link;