import styled from "@emotion/styled"

export type BulletProps = {
  color?: "success" | "error" | "info" | "warning" | "black";
  size?: number | string;
};

const Bullet = styled.span<BulletProps>(
  ({ theme, color = "black", size = 4 }) => ({
    borderRadius: "50%",
    width: size,
    height: size,
    display: "inline-block",
    backgroundColor:
      color === "black" ? "#263238" : (theme as any).colors[color],
  })
);

export default Bullet
