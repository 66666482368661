import { z } from "zod";

import { Slot } from "./slot";
import { DateSchema } from "./DateSchema";
import { ENV_VARIABLES } from "../../infrastructure/environments";

export enum CampaignState {
  CREATED = 100,
  TO_VERIFY = 200,
  APPROVED = 210,
  IN_PROGRESS = 220,
  COMPLETED = 230,
  REJECTED = 240,
  ERR_PAY = 300,
}

const daysDiff = (left: Date, right: Date) => (left.valueOf() - right.valueOf()) / 1000 / 60 / 60 / 24;

export const Campaign = z
  .object({
    id: z.string(),
    name: z.string(),
    price: z.number(),
    state: z.nativeEnum(CampaignState),
    from_date: DateSchema,
    to_date: DateSchema,
    reserved_at: DateSchema,
    image: z.object({
      id: z.string(),
    }),
    slots: z.array(Slot),
    city: z.object({
      name: z.string(),
    }),
    payment_type: z.union([z.enum(["EXTERNAL"]), z.string()]).optional(),
    is_paid: z.boolean().optional()
  })
  .transform(
    ({
      id,
      name,
      slots,
      price,
      reserved_at,
      state,
      from_date,
      to_date,
      image,
      city,
      payment_type,
      is_paid
    }) => {
      return {
        id,
        name,
        city,
        slots: slots,
        price,
        reservedAt: reserved_at,
        status: state,
        fromDate: from_date,
        toDate: to_date,
        days: daysDiff(to_date, from_date),
        imgSrc: `${ENV_VARIABLES.REACT_APP_BASE_PATH}/consumer/image/${image.id}`,
        paymentType: payment_type,
        isPaid: is_paid,
      };
    }
  );

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Campaign = z.TypeOf<typeof Campaign>;

const Campaigns = z.array(Campaign);

export type SortBy =
  | "name"
  | "state"
  | "user"
  | "city"
  | "price"
  | "reserved_at"
  | "from_date"
  | "state_at"
  | "created_at";

export type SortMode = 1 | 2;

export type GetCampaignsRequest = {
  state: CampaignState;
  page: number;
  size: number;
  sort_by?: SortBy;
  sort_mode?: SortMode;
};

export const getCampaigns = async (request: GetCampaignsRequest) => {
  const filter = new URLSearchParams(
    Object.entries(request)
      .filter(([_, val]) => val !== undefined)
      .map(([key, value]) => [key, value.toString()])
  );

  const response = await (
    await fetch(
      `${
        ENV_VARIABLES.REACT_APP_BASE_PATH
      }/consumer/video/campaigns?${filter.toString()}`
    )
  ).json();

  return Campaigns.parse(response.results);
}

export const getCampaign = async (id: string) => {
  const response = await fetch(
    `${ENV_VARIABLES.REACT_APP_BASE_PATH}/consumer/video/campaign/info?campaign_id=${id}`
  );

  return Campaign.parse(await response.json());
}

const StateCountSchema = z.object({
  count: z.number(),
  state: z.number(),
});

export type StateCount = z.TypeOf<typeof StateCountSchema>;

export const getStateCount = async () => {
  const response = await fetch(
    `${ENV_VARIABLES.REACT_APP_BASE_PATH}/consumer/video/campaigns/state/count`
  );

  return z.array(StateCountSchema).parse(await response.json());
}
