import { FC } from "react";
import { Text, Stack } from "@alexpireddu/map-ui";
import styled from "@emotion/styled";

import { MainPage } from "../../components";
import mobile from "../../assets/mobile.svg";

const SVGImage = styled.img({
  width: "35%",
  alignSelf: "center",
});

const Mobile: FC = () => {
  return (
    <MainPage variant="main" hasFindOutMore disableAlert>
      <Stack
        spacing={2}
        paddingTop="0.5rem"
        paddingBottom="0.5rem"
        paddingLeft="0.5rem"
        paddingRight="0.5rem"
        alignItems="center"
      >
        <Text fontWeight="bold" fontSize="1.125rem" textAlign="center">
          Collegati su PC o Tablet
          <br />
          per prenotare la tua campagna.
        </Text>
        <SVGImage src={mobile} alt={"PC Tablet"} />
      </Stack>
    </MainPage>
  );
};

export default Mobile;
