import {
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues,
  PathValue,
} from "react-hook-form";
import { Toggle } from "@alexpireddu/map-ui";
import {
  ChangeEvent,
  ChangeEventHandler,
  ComponentProps,
  FC,
  FocusEventHandler,
} from "react";
import { match } from "ts-pattern";

import { TextField, TextFieldProps } from "../TextField";
import { DistributiveOmit } from "../../utils";

export type ControlledInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TOutput extends PathValue<TFieldValues, TName> = TFieldValues[TName]
> = Pick<ControllerProps<TFieldValues, TName>, "control" | "name"> &
  DistributiveOmit<InputProps, "value" | "onChange"> & {
    className?: string;
    transform?: {
      output?: (value: ChangeEvent<HTMLInputElement>) => TOutput;
    };
  };

const ControlledInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  control,
  name,
  transform,
  ...rest
}: ControlledInputProps<TFieldValues, TName>) => {
  const transformOutput =
    transform?.output ||
    ((e: ChangeEvent<HTMLInputElement>) => {
      return e;
    });

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => {
        const restProps = match(rest.variant)
          .with("toggle", () => ({
            onChange,
          }))
          .otherwise(() => ({
            onBlur,
            errorText: error?.message,
            error: Boolean(error),
            onChange: (e: ChangeEvent<HTMLInputElement>) =>
              onChange(transformOutput(e)),
          }));
        return <Input {...rest} value={value} {...restProps} />;
      }}
    />
  );
}

type TextFieldBase = {
  label?: string;
  placeholder?: string;
  helperText?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  fullWidth?: boolean;
};

type TextVariant = TextFieldBase & {
  variant: "text";
  value: string | number;
  onChange: ChangeEventHandler<HTMLInputElement>;
};

type SelectVariant = TextFieldBase & {
  variant: "select";
  options: TextFieldProps["options"];
  value: string | number;
  onChange: (newValue: string | number) => void;
  isSearchable?: boolean;
};

type ToggleVariant = {
  variant: "toggle";
  value: boolean;
  onChange: ComponentProps<typeof Toggle>["onChange"];
};

type InputProps = TextVariant | SelectVariant | ToggleVariant;

const Input: FC<InputProps> = (props) => {
  return match(props)
    .with({ variant: "text" }, ({ variant, ...rest }) => (
      <TextField {...rest} />
    ))
    .with({ variant: "select" }, ({ variant, ...rest }) => (
      <TextField select {...rest} />
    ))
    .with({ variant: "toggle" }, ({ value, onChange }) => (
      <Toggle checked={value} onChange={onChange} />
    ))
    .exhaustive();
}

export default ControlledInput;
