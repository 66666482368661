import { useQuery } from "@tanstack/react-query";

import { getStateCount } from "../api/queries";

const queryKey = ["state-count"];

export const useStateCount = () => useQuery({
    queryKey,
    queryFn: getStateCount,
  });
