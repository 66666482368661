import { FC } from "react";
import { Stack } from "@alexpireddu/map-ui";
import styled from "@emotion/styled";

import { Currency } from "../Currency";
import { useMediaQuery } from "../../hooks";

const CheckboxContainer = styled.div<{
  variant?: "normal" | "disabled";
  checked?: boolean;
}>(({ variant, checked, theme }) => ({
  minHeight: 64,
  padding: 16,
  borderRadius: 8,
  border: `1px solid ${(theme as any).colors.neutral500}`,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  color: "#21c5fd",
  position: "relative",
  cursor: "pointer",
  ...(variant === "disabled" && {
    backgroundColor: "#cfd8dc",
    border: "none",
  }),
  ...(checked && {
    background:
      "linear-gradient(45deg, rgba(255,26,144,1) 0%, rgba(0,187,250,1) 100%)",
    color: "white",
    border: "none",
  }),
}));

const InputContainer = styled.span<{
  checked?: boolean;
}>(({ checked, theme }) => ({
  borderRadius: ".25em",
  aspectRatio: "1 / 1",
  height: "1.8em",
  lineHeight: 1,
  textAlign: "center",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  border: `1px solid ${(theme as any).colors.neutral500}`,
  ...(checked && {
    borderColor: "white",
  }),
  fontWeight: "bold",
  fontSize: "0.875rem",
  position: "relative",
  cursor: "pointer",
  input: {
    cursor: "inherit",
    opacity: 0,
    height: "100%",
    width: "100%",
    top: 0,
    left: 0,
    margin: 0,
    padding: 0,
    zIndex: 1,
    position: "absolute",
  },
  backgroundColor: checked ? "#263238" : undefined,
}));

const StyledStack = styled(Stack)({
  position: "relative",
});

const StyledCurrency = styled(Currency)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
});

const DangerText = styled.span({
  fontSize: "0.75rem",
  fontWeight: "bold",
  color: "#c60000",
  lineHeight: 1,
  position: "absolute",
  bottom: 4,
});

const DisabledText = styled.span({
  fontSize: "0.875rem",
  color: "#607d8b",
});

const Icon = styled.i({
  fontVariationSettings: '"FILL" 0, "wght" 600, "GRAD" 0, "opsz" 48',
});

export type SlotsCheckboxProps = {
  price: number;
  disabled?: boolean;
  availableSpaces: number;
  checked?: boolean;
  onChange: (checked: boolean) => void;
};

export const SlotsCheckbox: FC<SlotsCheckboxProps> = ({
  price,
  checked,
  disabled,
  onChange,
  availableSpaces,
}) => {
  const matches = useMediaQuery("(min-width: 880px)");

  if (disabled) {
    return (
      <CheckboxContainer variant="disabled">
        <DisabledText>Spazi Esauriti</DisabledText>
      </CheckboxContainer>
    );
  }

  return (
    <CheckboxContainer
      checked={checked}
      onClick={() => onChange && onChange(!checked)}
    >
      <StyledStack
        direction="row"
        alignItems="center"
        width="100%"
        justifyContent="end"
      >
        <StyledCurrency value={price} color={checked ? "#fff" : "#0095c8"} />
        {matches && (
          <InputContainer checked={checked}>
            {checked && (
              <Icon className="material-symbols-outlined">check</Icon>
            )}
            <input
              type="checkbox"
              checked={checked}
              onChange={(e) => onChange && onChange(!checked)}
            />
          </InputContainer>
        )}
      </StyledStack>
      {!checked && availableSpaces <= 3 && (
        <DangerText>
          {availableSpaces > 1
            ? `Restano solo ${availableSpaces} spazi`
            : `Resta solo 1 spazio`}
        </DangerText>
      )}
    </CheckboxContainer>
  );
};
