import { FC, useState } from 'react';
import { Card } from '@alexpireddu/map-ui';
import { Button, Stack, Text } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { SlotsTable, InstallationsMap, FindOutMore } from '../../components';
import pin from '../../assets/PIN.svg';
import { useCreateCampaignContext } from '../../hooks/CreateCampaignManager/CreateCampaignManager';
import ControlledInput from '../../components/ControlledInput';
import { CommandsBar } from './CommandsBar';
import { useNavigate } from 'react-router-dom';

const FlexButton = styled(Button)({
  flex: '1',
  display: 'flex',
  alignItems: 'center',
  gap: 10,
  whiteSpace: 'nowrap',
  paddingLeft: "2rem",
  paddingRight: "2rem"
});

const Image = styled.img({
  height: 18,
});

const MAX_LENGTH_NAME = 50;

export type GeneralInformationValue = {
  name: string;
  slots: number[];
};

const GeneralInformationValueSchema = z.object({
  name: z.string().nonempty('Il campo non può essere vuoto'),
  slots: z.array(z.number()).nonempty(),
});

export const GeneralInformation: FC = () => {
  const { campaign, setCampaign, pagedSlots, site, cityId, nextSlotsPage, prevSlotsPage } =
    useCreateCampaignContext();

  const navigate = useNavigate();

  const position = [
    Number.parseFloat(site.latitude),
    Number.parseFloat(site.longitude),
  ] as [number, number];

  const [openRules, setOpenRules] = useState(false);
  const [openInstallationsMap, setInstallationsMap] = useState(false);

  const { control, watch, formState, handleSubmit } = useForm({
    defaultValues: {
      name: campaign.name,
      slots: campaign.slots,
    },
    resolver: zodResolver(GeneralInformationValueSchema),
    mode: 'all',
  });

  const name = watch('name');
  const slots = watch('slots');

  return (
    <>
      <Stack spacing={4}>
        <FindOutMore open={openRules} onClose={() => setOpenRules(false)} />
        <InstallationsMap
          open={openInstallationsMap}
          position={position}
          onClose={() => setInstallationsMap(false)}
        />
        <Stack direction="row">
          <Text as="h2">Prenota la tua campagna</Text>
          <Stack direction="row" spacing={3} marginLeft="auto">
            <Button variant="secondary" onClick={() => setOpenRules(true)}>
              Istruzioni
            </Button>
            <FlexButton
              variant="accent"
              onClick={() => setInstallationsMap(true)}
            >
              <Image src={pin} alt="pin" />
              Mappa impianto
            </FlexButton>
          </Stack>
        </Stack>
        <Card size="lg">
          <Stack spacing={2}>
            <Text as="h3" fontWeight="bold">
              Scegli nome campagna
            </Text>
            <ControlledInput
              variant="text"
              control={control}
              name="name"
              placeholder="Es. Fiera dell'antiquariato"
              helperText={`${name.length} / ${MAX_LENGTH_NAME}`}
            />
          </Stack>
        </Card>
        <Card size="lg">
          <Stack spacing={1}>
            <div>
              <Text as="h3" fontWeight="bold">
                Scegli i tuoi spazi
              </Text>
              <Text marginTop={8} fontSize=".875rem" color="grey" marginBottom={16}>
                Minimo una settimana e una fascia oraria
              </Text>
            </div>
            <Controller
              control={control}
              name="slots"
              render={({ field: { value, onChange } }) => {
                return (
                  <SlotsTable
                    slots={pagedSlots}
                    value={value}
                    price={site.price}
                    onChange={(newSlotsValue) => onChange(newSlotsValue)}
                  />
                );
              }}
            />
            <Stack direction="row" spacing={2}>
              <Button
                marginLeft={44}
                variant="secondary"
                size="xs"
                onClick={() => prevSlotsPage()}
                icon="arrow-up"></Button>
              <Button
                variant="secondary"
                size="xs"
                onClick={() => nextSlotsPage()}
                icon="arrow-down"></Button>
            </Stack>
          </Stack>
        </Card>
      </Stack>
      <CommandsBar
        variant="general-information"
        slots={slots.length}
        slotPrice={site.price}
        canGoNext={formState.isValid}
        onNext={handleSubmit((val) => {
          setCampaign(val);
          navigate(`/campaign/create/${cityId}/uploading-image`);
        })}
      />
    </>
  );
};
