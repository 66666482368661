import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { resetSentryUser } from "../../infrastructure/sentryConfigurations";
import { ENV_VARIABLES } from "../../infrastructure/environments";

export const useLogout = () => {
  const client = useQueryClient();

  const handleLogout = useCallback(async () => {
    try {
      await fetch(`${ENV_VARIABLES.REACT_APP_BASE_PATH}/subscriber/logout`);

      resetSentryUser();
    } finally {
      client.invalidateQueries(["auth"]);
    }
  }, [client]);

  return handleLogout;
}
