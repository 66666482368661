import { Navigate } from "react-router-dom";
import { Authenticated, NotFoundErrorPage } from "../components";
import { Login } from "../pages";
import { campaignCreation } from "./campaignCreation";
import { commonRouting } from "./commonRouting";
import { MobileGuard } from "./MobileGuard";
import { sentryCreateBrowserRouter } from "../infrastructure/sentryConfigurations";

export const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <MobileGuard />,
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "/",
        element: <Authenticated />,
        children: [
          {
            index: true,
            element: <Navigate to="campaign/create" replace />,
          },
          commonRouting,
          campaignCreation,
          {
            path: '*',
            element: <NotFoundErrorPage />,
          },
        ],
      },
    ],
  },
]);
