import { FC, PropsWithChildren, Suspense } from "react";
import { Container } from "@alexpireddu/map-ui";
import styled from "@emotion/styled";
import { match } from "ts-pattern";

import { Header } from "../Header";
import { Loading } from "../Loading";

const Root = styled("div")({
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
});

const StyledContainer = styled(Container)({
  flex: 1,
});

export type CommonLayoutProps = {
  variant?: "normal" | "campaign-creation" | "main";
};

const CommonLayout: FC<PropsWithChildren<CommonLayoutProps>> = ({
  children,
  variant = "normal",
}) => {
  const headerProps = match(variant)
    .with(
      "main",
      () =>
        ({
          variant: "trasparent",
          avatar: false,
        } as const)
    )
    .with(
      "campaign-creation",
      () =>
        ({
          variant: "trasparent",
        } as const)
    )
    .with(
      "normal",
      () =>
        ({
          variant: "normal",
          border: true,
        } as const)
    )
    .exhaustive();

  return (
    <Root>
      <Header avatar {...headerProps} top={0} zIndex={10} />
      <Suspense fallback={<Loading />}>
        <StyledContainer maxWidth="xl">{children}</StyledContainer>
      </Suspense>
    </Root>
  );
};

export default CommonLayout;
