import { FC } from "react";
import { LoadingSpinner } from "@alexpireddu/map-ui";
import styled from "@emotion/styled";

const Container = styled.div({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const Loading: FC = () => {
  return (
    <Container>
      <LoadingSpinner size="xl" />
    </Container>
  );
};

export default Loading;
