import { FC } from "react";
import { Container, Text, Stack, Card } from "@alexpireddu/map-ui";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";

import { CampaignCard, Tooltip } from "../../components";
import { useAuthenticated, useCampaign } from "../../hooks";

const Root = styled("div")({
  width: "100%",
  aspectRatio: "4 / 3",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  backgroundColor: "#cfd8dc",
  borderRadius: 12,
  gap: 12,
});

const Image = styled("img")({
  position: "absolute",
  maxWidth: "100%",
});

const SpaceBetweenText = styled(Text)({
  display: "flex",
  justifyContent: "space-between",
});

const BottomBorderText = styled(SpaceBetweenText)({
  borderBottom: "0.5px solid #cfd8dc",
});

const currencyFormat = new Intl.NumberFormat(navigator.language, {
  style: "currency",
  currency: "EUR",
});

const format = (value: number) => {
  const currencyStr = currencyFormat.format(value);

  return currencyStr.split(" ").reverse().join(" ");
};

const COMMISSIONS = 2;

const VAT_MUL = 1.22;

const VAT = 22;

const computeVATOnPrice = (slotPrice: number) => {
  const noVat = slotPrice / VAT_MUL;

  return Number((slotPrice - noVat).toFixed(2));
}

const Campaign: FC = () => {
  const { user } = useAuthenticated().data!;

  const { campaignId } = useParams<{ campaignId: string }>();
  const campaign = useCampaign(campaignId!).data!;

  return (
    <Container disableXGutters yGutters maxWidth="md">
      <Stack spacing={4}>
        <Card size="lg">
          <Stack spacing={2}>
            <Text as="h3" fontWeight="bold">
              Prenotazione campagna
            </Text>
            <Stack spacing={3}>
              <CampaignCard
                user={user}
                name={campaign.name}
                slots={campaign.slots}
              />
              <div>
                <BottomBorderText>
                  Tariffa spazio {campaign.city.name}
                  <Text as="span" fontWeight="bold">
                    {format(campaign.price / campaign.slots.length)}
                  </Text>
                </BottomBorderText>
                <BottomBorderText>
                  <span />
                  <Text as="span" color="#b1b1b1">
                    {`Di cui IVA (${VAT}%) ${format(
                      computeVATOnPrice(campaign.price / campaign.slots.length)
                    )}`}
                  </Text>
                </BottomBorderText>
                <BottomBorderText>
                  Quantità spazi
                  <Text as="span" fontWeight="bold">
                    {campaign.slots.length}
                  </Text>
                </BottomBorderText>
                <BottomBorderText>
                  <Text as="span" fontWeight="bold">
                    Sub-totale
                  </Text>
                  <Text as="span" fontWeight="bold">
                    {format(campaign.price)}
                  </Text>
                </BottomBorderText>
                <BottomBorderText>
                  <Tooltip
                    content={
                      <Text as="span" padding={8} fontSize="0.875rem">
                        Commissione che Publista deve riconoscere allo strumento
                        di pagamento
                      </Text>
                    }
                  >
                    Commissioni pagamento
                  </Tooltip>
                  <Text as="span" fontWeight="bold">
                    {format(COMMISSIONS)}
                  </Text>
                </BottomBorderText>
              </div>
              <SpaceBetweenText as="h3" fontWeight="bold">
                Totale
                <span>{format(campaign.price + COMMISSIONS)}</span>
              </SpaceBetweenText>
            </Stack>
          </Stack>
        </Card>
        <Root>
          <Image src={`${campaign.imgSrc}`} alt={campaign.name} />
        </Root>
      </Stack>
    </Container>
  );
};

export default Campaign;
