import { useQuery } from "@tanstack/react-query";

import { getProfile, User } from "../../api/queries";

export const useAuthenticated = () => useQuery(["auth"], async () => {
    try {
      const user = await getProfile();
      return {
        user,
        auth: true
      };
    } catch {
      return {
        user: {} as User,
        auth: false
      };
    }
  });
