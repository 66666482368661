import { AppBar, Toolbar, Stack } from '@alexpireddu/map-ui';
import { Stepper } from '@alexpireddu/map-ui';
import { FC, useMemo, useState } from 'react';
import styled from '@emotion/styled';

import { UserMenu } from '../UserMenu';
import { useCities, useCityParams, useMediaQuery } from '../../hooks';
import { LeaveFeedbackModal } from '../Feedback';
import { Link, useNavigate } from 'react-router-dom';
import { LeaveRoutePromptModal } from '../LeaveRoutePromptModal';
import PublistaTitle from '../PublistaTitle';
import TextField from '../TextField';
import CityLogo from '../CityLogo';

const MarginLeft = styled.div({
  marginLeft: 'auto',
});

const MainToolbar = styled(Toolbar)(({ variant }) => ({
  ...(variant === 'dense' && {
    paddingTop: 12,
    paddingBottom: 12,
  }),
}));

const steps = [
  { label: 'Prenotazione campagna' },
  { label: 'Carica immagine' },
  { label: 'Pagamento' },
];

export type CreateCampaignHeaderProps = {
  activeStep: number;
};

const CreateCampaignHeader: FC<CreateCampaignHeaderProps> = ({
  activeStep,
}) => {
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [promptOpen, setPromptOpen] = useState(false);
  const { cityId } = useCityParams();
  const navigate = useNavigate();
  const [feedbackModalOpen, setFeetbackModalOpen] = useState(false);
  const matches = useMediaQuery('(min-width: 1000px)');

  const cities = useCities().data!;
  const cityOptions = useMemo(() => {
    return cities.map((city) => ({
      value: city.id,
      label: (
        <Stack spacing={1} direction="row" alignItems="center">
          <CityLogo cityId={city.id} menu /> <span>{city.name}, {city.province.code}</span>
        </Stack>
      ),
    }));
  }, [cities]);

  return (
    <AppBar border top={0} variant="normal" zIndex={10}>
      <MainToolbar variant={matches ? 'normal' : 'dense'}>
        <Stack direction="row" alignItems="center" width="100%" spacing={3}>
          <Link
            to="/"
            style={{
              height: 40,
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              setPromptOpen(true);
            }}
          >
            <PublistaTitle
              style={{ cursor: 'pointer' }}
              width={150}
              height={40}
              fill="#363636"
            />
          </Link>
          <TextField select value={cityId} options={cityOptions} isDisabled />
          {matches && (
            <div style={{ flex: '1 auto' }}>
              <Stepper steps={steps} activeStep={activeStep} />
            </div>
          )}
          <MarginLeft>
            <UserMenu
              open={userMenuOpen}
              onClick={() => setUserMenuOpen((prev) => !prev)}
              onClickOutside={() => setUserMenuOpen(false)}
              onOpenFeedbackModal={() => setFeetbackModalOpen(true)}
            />
            <LeaveFeedbackModal
              open={feedbackModalOpen}
              cityId={cityId}
              onClose={() => setFeetbackModalOpen(false)}
            />
          </MarginLeft>
        </Stack>
      </MainToolbar>
      {!matches && (
        <Toolbar variant="dense">
          <div style={{ flex: '1 auto', marginBottom: '16px' }}>
            <Stepper steps={steps} activeStep={activeStep} />
          </div>
        </Toolbar>
      )}
      {promptOpen && (
        <LeaveRoutePromptModal
          onClose={() => setPromptOpen(false)}
          onConfirm={() => navigate('/')}
        />
      )}
    </AppBar>
  );
};

export default CreateCampaignHeader;
