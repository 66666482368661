import { FC } from "react";
import styled from "@emotion/styled";
import { Text, Stack } from "@alexpireddu/map-ui";

import { Bullet } from "../Bullet";
import { SlotsList, SlotsListProps } from "../SlotsList";

const Paper = styled("div")({
  borderRadius: 12,
  padding: 12,
  border: "1px solid #cfd8dc",
  backgroundColor: "rgba(236, 239, 241, 0.5)",
});

const TextsContainer = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: 6,
});

const StyledText = styled(Text)({
  display: "flex",
  gap: 4,
  alignItems: "center",
  flexWrap: "wrap",
});

const Bold = styled("span")({
  fontWeight: "bold",
});

const Image = styled("img")({
  aspectRatio: "4 / 3",
  minWidth: 105,
  maxWidth: 105,
  width: 105,
  objectFit: "contain",
});

const ImageContainer = styled(Stack)({
  textAlign: "start",
});

export type CampaignCardProps = {
  name: string;
  slots: SlotsListProps["slots"];
  user: {
    name: string;
    surname: string;
    email: string;
  };
  imgSrc?: string;
};

const CampaignCard: FC<CampaignCardProps> = ({ slots, user, name, imgSrc }) => {
  return (
    <Stack as={Paper} direction="row" spacing={1}>
      <TextsContainer>
        <StyledText>
          <Bold>Dati richiedente: </Bold>
          {`${user.name} ${user.surname}`} <Bullet /> {user.email}
        </StyledText>
        <StyledText>
          <Bold>Nome campagna: </Bold>
          {name}
        </StyledText>
        <StyledText
          style={{
            alignItems: "start",
          }}
        >
          <Bold>Spazi: </Bold>
          <SlotsList slots={slots} />
        </StyledText>
      </TextsContainer>
      {imgSrc && (
        <ImageContainer>
          <Bold>Contenuto: </Bold>
          <Image src={imgSrc} alt="campaign" />
        </ImageContainer>
      )}
    </Stack>
  );
};

export default CampaignCard;
