import { FC, useMemo } from "react";
import styled from "@emotion/styled";
import { Text } from "@alexpireddu/map-ui";

import { Tooltip, DateList } from "../";
import { AvailableSlotResponse } from "../../api/queries";
import { SlotsCheckbox } from "./SlotsCheckbox";

const Table = styled("table")({
  tableLayout: "fixed",
  borderCollapse: "collapse",
  width: "100%",
  tr: {
    borderBottom: "0.5px solid #cfd8dc",
  },
  "> tbody > tr:last-of-type": {
    border: "none",
  },
  "td:first-of-type": {
    borderRight: "0.5px solid #cfd8dc",
  },
  th: {
    padding: 8,
  },
  td: {
    padding: 16,
  },
});

const TooltipContainer = styled.div({
  maxWidth: 320,
  whiteSpace: "normal",
  minWidth: 320,
  ">*": { fontSize: "0.875rem" },
});

const Divider = styled.hr({
  height: 3,
  width: 12,
  marginTop: "-10%",
  backgroundColor: "#000",
});

const dateFormatter = new Intl.DateTimeFormat("it-IT", {
  month: "short",
  day: "numeric",
  year: "2-digit",
});

export type SlotsTableProps = {
  slots: AvailableSlotResponse;
  value: number[];
  price: number;
  onChange: (newValue: number[]) => void;
};

const SlotsTable: FC<SlotsTableProps> = ({ slots, value, onChange, price }) => {
  const slotsRows = useMemo(() => {
    return slots.map(({ from, to, quantities }) => {
      const morningChecked = value.includes(from.valueOf() * 3);
      const afternoonChecked = value.includes(from.valueOf() * 3 + 1);
      const eveningChecked = value.includes(from.valueOf() * 3 + 2);

      const fromDate = dateFormatter.formatToParts(from);
      const toDate = dateFormatter.formatToParts(to);

      return (
        <tr key={`${from.valueOf()}_${to.valueOf()}`}>
          <td>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: 8,
              }}
            >
              <Tooltip
                content={
                  <DateList
                    from={new Date(from.valueOf())}
                    to={new Date(to.valueOf())}
                  />
                }
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <Text as="h2">{fromDate[0].value}</Text>
                  <Text as="p" lineHeight={1} textTransform="uppercase">
                    {fromDate[2].value} {fromDate[4].value}
                  </Text>
                </div>
                <Divider />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <Text as="h2">{toDate[0].value}</Text>
                  <Text as="p" lineHeight={1} textTransform="uppercase">
                    {toDate[2].value} {toDate[4].value}
                  </Text>
                </div>
              </Tooltip>
            </div>
          </td>
          <td>
            <SlotsCheckbox
              price={price}
              checked={morningChecked}
              availableSpaces={quantities[0].quantity}
              onChange={() => {
                onChange(
                  morningChecked
                    ? value.filter((item) => item !== from.valueOf() * 3)
                    : [...value, from.valueOf() * 3]
                );
              }}
              disabled={quantities[0].quantity <= 0}
            />
          </td>
          <td>
            <SlotsCheckbox
              price={price}
              checked={afternoonChecked}
              availableSpaces={quantities[1].quantity}
              onChange={() => {
                onChange(
                  afternoonChecked
                    ? value.filter((item) => item !== from.valueOf() * 3 + 1)
                    : [...value, from.valueOf() * 3 + 1]
                );
              }}
              disabled={quantities[1].quantity <= 0}
            />
          </td>
          <td>
            <SlotsCheckbox
              price={price}
              checked={eveningChecked}
              availableSpaces={quantities[2].quantity}
              onChange={() => {
                onChange(
                  eveningChecked
                    ? value.filter((item) => item !== from.valueOf() * 3 + 2)
                    : [...value, from.valueOf() * 3 + 2]
                );
              }}
              disabled={quantities[2].quantity <= 0}
            />
          </td>
        </tr>
      );
    });
  }, [onChange, price, slots, value]);

  return (
    <Table>
      <thead>
        <tr>
          <th>SETTIMANA</th>
          <th>
            <Tooltip
              position="top"
              content={
                <TooltipContainer>
                  <Text textAlign="start" fontWeight="bold" marginBottom={8}>
                    Fascia oraria mattina
                  </Text>
                  <Text
                    textAlign="start"
                    whiteSpace="normal"
                    fontWeight="normal"
                    lineHeight={1.2}
                  >
                    Se selezioni uno spazio su questa colonna il tuo contenuto
                    verrà trasmesso dalle ore 6:00 alle ore 12:00, ad ogni
                    minuto per 10 secondi
                  </Text>
                </TooltipContainer>
              }
            >
              Mattina
            </Tooltip>
          </th>
          <th>
            <Tooltip
              position="top"
              content={
                <TooltipContainer>
                  <Text textAlign="start" fontWeight="bold" marginBottom={8}>
                    Fascia oraria pomeriggio
                  </Text>
                  <Text
                    textAlign="start"
                    whiteSpace="normal"
                    fontWeight="normal"
                    lineHeight={1.2}
                  >
                    Se selezioni uno spazio su questa colonna il tuo contenuto
                    verrà trasmesso dalle ore 12:00 alle ore 18:00, ad ogni
                    minuto per 10 secondi
                  </Text>
                </TooltipContainer>
              }
            >
              Pomeriggio
            </Tooltip>
          </th>
          <th>
            <Tooltip
              position="top"
              content={
                <TooltipContainer>
                  <Text textAlign="start" fontWeight="bold" marginBottom={8}>
                    Fascia oraria sera
                  </Text>
                  <Text
                    textAlign="start"
                    whiteSpace="normal"
                    fontWeight="normal"
                    lineHeight={1.2}
                  >
                    Se selezioni uno spazio su questa colonna il tuo contenuto
                    verrà trasmesso dalle ore 18:00 alle ore 24:00, ad ogni
                    minuto per 10 secondi
                  </Text>
                </TooltipContainer>
              }
            >
              Sera
            </Tooltip>
          </th>
        </tr>
      </thead>
      <tbody>{slotsRows}</tbody>
    </Table>
  );
};

export default SlotsTable;
