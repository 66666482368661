import { FC, useMemo } from "react";
import styled from "@emotion/styled";
import { TableFooter, Flex, Box, Button, Select } from "@alexpireddu/map-ui";

import { Campaign } from "../../api/queries";

import { TableRow } from "./TableRow";
import { TableHeader, TableHeaderProps } from "./TableHeader";

const Table = styled("table")({
  borderSpacing: 0,
  width: "100%",
  "> tbody > tr:nth-of-type(even)": {
    backgroundColor: "#eceff1",
  },
});

const EmptyRowCell = styled.td({
  padding: "12px 32px",
});

const EmptyRowContent = styled.div({
  display: "flex",
  justifyContent: "center",
});

export type AdvertisementsHistoryTableProps = {
  campaigns: Campaign[];
} & TableHeaderProps &
  PaginationProps;

const AdvertisementsHistoryTable: FC<AdvertisementsHistoryTableProps> = ({
  campaigns,
  orderBy,
  direction,
  onOrderChange,
  currentPage,
  pageSize,
  pageSizeRange,
  total,
  onPageSizeChange,
  onNextPage,
  onPrevPage,
}) => {
  const campaignRows = useMemo(() => {
    if (campaigns.length === 0) {
      return (
        <tr>
          <EmptyRowCell colSpan={8}>
            <EmptyRowContent>Nessun risultato</EmptyRowContent>
          </EmptyRowCell>
        </tr>
      );
    }

    return campaigns.map((campaign) => (
      <TableRow key={campaign.id} campaign={campaign} />
    ));
  }, [campaigns]);

  return (
    <Table>
      <TableHeader
        orderBy={orderBy}
        direction={direction}
        onOrderChange={onOrderChange}
      />
      <tbody>{campaignRows}</tbody>
      <TableFooter>
        <tr>
          <td colSpan={8}>
            <Flex alignItems="center" justifyContent="space-between">
              <Box>
                <span>Totale risultati: {total}</span>
              </Box>
              <Box>
                <Pagination
                  currentPage={currentPage}
                  pageSize={pageSize}
                  pageSizeRange={pageSizeRange}
                  total={total}
                  onPageSizeChange={onPageSizeChange}
                  onNextPage={onNextPage}
                  onPrevPage={onPrevPage}
                />
              </Box>
            </Flex>
          </td>
        </tr>
      </TableFooter>
    </Table>
  );
};

type PaginationProps = {
  pageSize: number;
  currentPage: number;
  pageSizeRange: number[];
  total: number;
  onPageSizeChange: (pageSize: number) => void;
  onNextPage: () => void;
  onPrevPage: () => void;
};

const Pagination: FC<PaginationProps> = ({
  currentPage,
  pageSize,
  pageSizeRange,
  total,
  onPageSizeChange,
  onNextPage,
  onPrevPage,
}) => {
  const totalPages = Math.ceil(total / pageSize);

  return (
    <PaginationRoot>
      <Flex alignItems="center" justifyContent="end">
        <Box>
          <span>Risultati per pagina: </span>
        </Box>
        <Box ml="8px">
          <Select
            value={pageSize}
            onChange={(value) => onPageSizeChange(Number(value))}
            size="xs"
            options={pageSizeRange.map((s) => ({
              value: s,
              label: s,
            }))}
          />
        </Box>
        <Box ml="16px">
          <Button
            variant="unstyled"
            icon="arrow-left"
            size="sm"
            disabled={currentPage === 1}
            onClick={onPrevPage}
          />
        </Box>
        <Box mr="16px">
          <Button
            variant="unstyled"
            icon="arrow-right"
            size="sm"
            disabled={currentPage === totalPages}
            onClick={onNextPage}
          />
        </Box>
        <Box>
          <span>
            Pagina {currentPage} di {totalPages}
          </span>
        </Box>
      </Flex>
    </PaginationRoot>
  );
};

const PaginationRoot = styled.div({
  display: "inline-block",
  ".__mapui_icon": {
    color: "white",
    fill: "white",
  },
  ".__mapui_select": {
    borderRadius: "6px",
  },
});

export default AdvertisementsHistoryTable;
