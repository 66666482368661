import { FC } from "react";
import { Sorting } from "@alexpireddu/map-ui";
import styled from "@emotion/styled";

import { TableCell } from "./TableCell";
import { SortBy } from "../../api/queries";

const TableRow = styled("tr")({
  "th:first-of-type": {
    paddingLeft: "32px",
    paddingRight: "32px",
    borderTopLeftRadius: 12,
  },
  "th:last-of-type": {
    paddingLeft: "32px",
    paddingRight: "32px",
    borderTopRightRadius: 12,
  },
});

const THead = styled.thead({
  borderTopLeftRadius: 12,
  borderTopRightRadius: 12,
});

export type TableHeaderProps = {
  orderBy?: string;
  direction?: "asc" | "desc";
  onOrderChange: (orderBy: SortBy) => void;
};

export const TableHeader: FC<TableHeaderProps> = ({
  onOrderChange: handleOrderChange,
  direction,
  orderBy,
}) => {
  return (
    <THead>
      <TableRow>
        <TableCell variant="th" width="calc(100% / 8)">
          Contenuto
        </TableCell>
        <TableCell variant="th" textAlign="start" width="calc(100% / 8)">
          Comune
          <Sorting
            sort={orderBy === "city" ? direction! : "unset"}
            onChangeSorting={() => handleOrderChange("city")}
          />
        </TableCell>
        <TableCell variant="th" textAlign="start" width="calc(100% / 8)">
          Periodo
          <Sorting
            sort={orderBy === "from_date" ? direction! : "unset"}
            onChangeSorting={() => handleOrderChange("from_date")}
          />
        </TableCell>
        <TableCell variant="th" width="calc(100% / 8)">
          Nome campagna
          <Sorting
            sort={orderBy === "name" ? direction! : "unset"}
            onChangeSorting={() => handleOrderChange("name")}
          />
        </TableCell>
        <TableCell variant="th" width="calc(100% / 8)">
          Spazi
          {/* <Sorting
            sort={orderBy === "slots" ? direction! : "unset"}
            onChangeSorting={() => handleOrderChange("")}
          /> */}
        </TableCell>
        <TableCell variant="th" width="calc(100% / 8)">
          Prenotazione
          <Sorting
            sort={orderBy === "reserved_at" ? direction! : "unset"}
            onChangeSorting={() => handleOrderChange("reserved_at")}
          />
        </TableCell>
        <TableCell variant="th" width="calc(100% / 8)">
          Imposta
          <Sorting
            sort={orderBy === "price" ? direction! : "unset"}
            onChangeSorting={() => handleOrderChange("price")}
          />
        </TableCell>
        <TableCell variant="th" width="calc(100% / 8)">
          Stato
          <Sorting
            sort={orderBy === "state_at" ? direction! : "unset"}
            onChangeSorting={() => handleOrderChange("state_at")}
          />
        </TableCell>
      </TableRow>
    </THead>
  );
};
