import { FC, useCallback } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import { Map, divIcon } from "leaflet";
import { Modal, ModalCloseButton } from "@alexpireddu/map-ui";
import styled from "@emotion/styled";
import "./Marker.css";
import { ClickAwayListener } from "@mui/base";

const StyledModal = styled(Modal)({
  section: {
    maxWidth: "none",
    margin: 0,
    width: "90vw",
    borderRadius: 0,
  },
  justifyContent: "end",
});

const Container = styled.div({
  height: "100vh",
  width: "90vw",
});

const StyledModalCloseButton = styled(ModalCloseButton)({
  zIndex: 1000,
  aspectRatio: "1 / 1",
});

export type InstallationsMapProps = {
  position: [number, number];
  onClose: () => void;
  open?: boolean;
};

const iconMarkup = renderToStaticMarkup(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="48px"
    viewBox="0 0 24 24"
    width="48px"
    fill="#000000"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
    <circle cx="12" cy="9" r="2.5" fill="#fff" />
  </svg>
);
const customMarkerIcon = divIcon({
  html: iconMarkup,
  iconSize: [48, 48],
  className: "marker",
});

const InstallationsMap: FC<InstallationsMapProps> = ({
  position,
  onClose,
  open,
}) => {
  const mapCallbackRef = useCallback((ref: Map) => {
    ref && ref.invalidateSize();
  }, []);

  const handleOpen = () => {
    document.body.style.overflow = "hidden";
  };

  const handleClose = () => {
    document.body.style.overflow = "";
  };

  if (!open) {
    return null;
  }

  return (
    <StyledModal onOpen={handleOpen} onClose={handleClose}>
      <ClickAwayListener onClickAway={onClose}>
        <Container>
          <StyledModalCloseButton onClick={onClose} />
          <MapContainer
            style={{
              height: "100%",
            }}
            ref={mapCallbackRef}
            center={position}
            zoom={18}
            scrollWheelZoom={false}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker position={position} icon={customMarkerIcon}></Marker>
          </MapContainer>
        </Container>
      </ClickAwayListener>
    </StyledModal>
  );
};

export default InstallationsMap;
