import { Outlet, RouteObject } from "react-router-dom";
import { CommonLayout, NotFoundErrorPage } from "../components";
import {
  AdvertisementsHistory,
  Campaign,
  CampaignUploadImage,
  Support,
} from "../pages";

export const commonRouting: RouteObject = {
  path: "*",
  element: (
    <CommonLayout>
      <Outlet />
    </CommonLayout>
  ),
  children: [
    {
      path: "support",
      element: <Support />,
    },
    {
      path: "advertising-history",
      element: <AdvertisementsHistory />,
    },
    {
      path: "campaign/:campaignId",
      element: <Campaign />,
    },
    {
      path: "campaign/:campaignId/upload-image",
      element: <CampaignUploadImage />,
    },
    {
      path: '*',
      element: <NotFoundErrorPage />,
    },
  ],
};
