import { z } from "zod";
import { ENV_VARIABLES } from "../../infrastructure/environments";

export const City = z.object({
  id: z.string(),
  name: z.string(),
  province: z.object({
    code: z.string(),
  }),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type City = z.TypeOf<typeof City>;

const Cities = z.array(City);

export const getCities = async () => {
  const result = await (
    await fetch(`${ENV_VARIABLES.REACT_APP_BASE_PATH}/consumer/cities`)
  ).json();
  return Cities.parse(result);
}
