import { ENV_VARIABLES } from "./infrastructure/environments";

const browserFetch = fetch;
window.fetch = (...args) => {
  if (args[1]) {
    args[1] = {
      ...args[1],
      headers: {
        ...args[1].headers,
        ...(ENV_VARIABLES.REACT_APP_X_API_KEY && {
          "X-API-KEY": ENV_VARIABLES.REACT_APP_X_API_KEY,
        }),
      },
      credentials: args[1].credentials || "include",
    };
  } else {
    args[1] = {
      headers: {
        ...(ENV_VARIABLES.REACT_APP_X_API_KEY && {
          "X-API-KEY": ENV_VARIABLES.REACT_APP_X_API_KEY,
        }),
      },
      credentials: "include",
    };
  }
  return browserFetch(...args);
};

export {};
