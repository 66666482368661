import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import {
  createCampaign,
  putCampaign,
  payCampaign,
  PayCampaignRequest,
  PutCampaignRequest,
  CreateCampaignRequest,
  planCampaign,
} from '../../api/mutations';

type Request =
  | ({
      type: 'create';
    } & CreateCampaignRequest)
  | ({
      type: 'put';
    } & PutCampaignRequest)
  | ({
      type: 'pay';
    } & PayCampaignRequest)
  | {
      type: 'plan';
      campaign_id: string;
    };

export const useCreateCampaign = () => {
  const queryClient = useQueryClient();
  const mutation = useCallback((request: Request) => {
    switch (request.type) {
      case 'create': {
        const { type, ...rest } = request;
        return createCampaign(rest);
      }
      case 'pay': {
        const { type, ...rest } = request;
        return payCampaign(rest);
      }
      case 'put': {
        const { type, ...rest } = request;
        return putCampaign(rest);
      }
      case 'plan': {
        return planCampaign(request.campaign_id);
      }
    }
  }, []);
  return useMutation(mutation, {
    onMutate: () => {
      queryClient.cancelQueries(['campaigns']);
      queryClient.invalidateQueries(['campaigns']);

      queryClient.cancelQueries(['campaign']);
      queryClient.invalidateQueries(['campaign']);
    },
  });
};
