import { useEffect, useState } from "react";

export const usePages = (total: number) => {
  const [pageSize, setPageSize] = useState(5);
  const pageSizeRange = [5, 10, 20];
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(total / pageSize) || 1;

  useEffect(() => {
    if(currentPage > totalPages) {
        setCurrentPage(totalPages)
    }
  }, [currentPage, totalPages])

  return {
    pageSize,
    pageSizeRange,
    currentPage,
    total,
    onPageSizeChange: (value: number) => setPageSize(value),
    onNextPage: () => setCurrentPage((cp) => ++cp),
    onPrevPage: () => setCurrentPage((cp) => --cp),
  };
}
