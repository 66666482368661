import { z } from "zod";
import { ENV_VARIABLES } from "../../infrastructure/environments";

export const ProvinceSchema = z.object({
  id: z.string(),
  code: z.string(),
  name: z.string(),
});

export type Province = z.TypeOf<typeof ProvinceSchema>;

const ProvincesSchema = z.array(ProvinceSchema);

export const getProvinces = async () => {
  const response = await fetch(`${ENV_VARIABLES.REACT_APP_BASE_PATH}/provinces`);

  return ProvincesSchema.parse(await response.json());
}
