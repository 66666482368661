import { Box, Button, FormControl, Label, Stack, Text, TextArea } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import { FC, useState, ChangeEvent } from 'react';
import FeedbackSent from './FeedbackSent';
import { SendFeedbackIcon } from './Icons';
import SentimentsPanel, { Sentiment } from './SentimentsPanel';

export type Feedback = {
    section: string;
    feedback: string;
    notes?: string;
    city_id?: string;
    additional_data?: string;
}

export type SendFeedbackFn = (data: Feedback) => Promise<Response>;

export interface FeedbackControlProps {
    section: string;
    cityId?: string;
    additionalData?: any;
    sendFeedbackFn: SendFeedbackFn;
    width?: number | string;
}

const FixedTextArea = styled(TextArea)({
    resize: "none"
});

const SendIcon = styled(SendFeedbackIcon)({
    width: 16,
    height: 16,
    position: "relative",
    top: 3,
    marginRight: 4,
    marginLeft: 4,
});

const SendButton = styled(Button)({
    paddingTop: "calc(0.5rem - 2px)",
})

const FeedbackControl: FC<FeedbackControlProps> = ({
    section,
    cityId = undefined,
    additionalData,
    sendFeedbackFn,
    width = 350,
}) => {
    const [sentiment, setSentiment] = useState<Sentiment>("NONE");
    const [formValid, setFormValid] = useState<boolean>(false);
    const [notes, setNotes] = useState<string>();
    const [feedbackSent, setFeedbackSent] = useState<boolean>(false);

    const handleSentimentSelection = (s: Sentiment) => {
        setSentiment(s);
        setFormValid(true);
    }

    const handleFeedbackSend = () => {
        if (sentiment === "NONE") {
            setFormValid(false);
        } else {
            setFormValid(true);
            sendFeedbackFn({
                section: section,
                city_id: cityId,
                notes: notes,
                feedback: sentiment,
                additional_data: additionalData,
            }).then(() => {
                setFeedbackSent(true);
            });
        }
    }

    const resetFeedbackSent = () => {
        setFeedbackSent(false);
        setNotes(undefined);
        setSentiment("NONE");
        setFormValid(false);
    }

    return feedbackSent ?
        <FeedbackSent onClick={resetFeedbackSent} width={width} />
        : (
            <Stack
                alignItems="center"
                marginTop="1rem"
                spacing={1}
                width={width}
            >
                <Box justifyContent="center">
                    <SentimentsPanel onSelect={handleSentimentSelection} disabled={feedbackSent} />
                </Box>
                <Box width={width}>
                    <FormControl>
                        <Label>Lascia un commento</Label>
                        <FixedTextArea
                            disabled={feedbackSent}
                            rows={5}
                            value={notes}
                            maxLength={250}
                            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setNotes(e.target.value)}
                        />
                        <Text color="grey" textAlign="right" fontSize={12}>
                            {notes?.length ?? 0} / 250
                        </Text>
                    </FormControl>
                </Box>
                <SendButton
                    isFullWidth
                    variant='primary'
                    onClick={handleFeedbackSend}
                    disabled={!formValid}
                    size="sm">
                    <SendIcon active={formValid} /><span>Invia feedback</span>
                </SendButton>
            </Stack >
        );
}

export default FeedbackControl;