import { FC } from "react";
import { Button, Stack, Text } from "@alexpireddu/map-ui";
import styled from "@emotion/styled";

import payment_error from "../../assets/payment_error.png";
import { useNavigate } from "react-router-dom";
import { LeaveFeedbackEmbedded, CampaignCreatedLayout } from "../../components";
import { useCityParams } from "../../hooks";

const Image = styled("img")({
  maxWidth: 320,
  objectFit: "contain",
});

const Bold = styled.span({
  fontWeight: "bold",
});

const PaymentFailed: FC = () => {
  const navigate = useNavigate();
  const { cityId } = useCityParams();

  return (
    <CampaignCreatedLayout>
      <Stack alignItems="center" spacing={2.5}>
        <Text as="h1" fontSize="2.8rem">
          Il pagamento non ha funzionato.
        </Text>
        <Text textAlign="center">
          Purtroppo il pagamento non è andato a buon fine … ma non ti arrendere!
          <br />
          Ti invitiamo a <Bold>riprovare</Bold> o a{" "}
          <Bold>cambiare carta/modalità di pagamento</Bold>.
        </Text>
        <Stack direction="row" spacing={8}>
          <Image src={payment_error} alt="payment error" />
          <Stack direction="column" spacing={1}>
            <LeaveFeedbackEmbedded
              section="CAMPAIGN_CANCEL_PAYMENT"
              cityId={cityId}
              width={396}
            />
            <Button
              variant="accent"
              onClick={() => navigate("/campaign/create")}
              marginTop="1rem"
            >
              NUOVA PRENOTAZIONE
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </CampaignCreatedLayout>
  );
};

export default PaymentFailed;
