import { FC, useMemo, useState } from "react";
import { Stack, Button } from "@alexpireddu/map-ui";
import { useNavigate } from "react-router-dom";

import { TextField, MainPage } from "../../components";
import { useCities } from "../../hooks";
import CityLogo from "../../components/CityLogo";

const CitySelection: FC = () => {
  const cities = useCities().data!;
  const cityOptions = useMemo(() => {
    return cities.map((city) => ({
      value: city.id,
      label: (
        <Stack spacing={1} direction="row" alignItems="center">
          <CityLogo cityId={city.id} menu /> <span>{city.name}, {city.province.code}</span>
        </Stack>
      ),
    }));
  }, [cities]);
  const [city, setCity] = useState("");
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  return (
    <MainPage hasFindOutMore>
      <Stack direction="row" alignItems="end" spacing={2} width={600}>
        <TextField
          select
          fullWidth
          label="Comune"
          value={city}
          onChange={(newCity) => {
            setCity(newCity.toString());
            setError(false);
          }}
          options={cityOptions}
          placeholder="Seleziona comune"
          error={error}
        />
        <Button
          size="sm"
          variant="accent"
          onClick={() => {
            if (city) {
              navigate(city);
            } else {
              setError(true);
            }
          }}
        >
          INIZIAMO
        </Button>
      </Stack>
    </MainPage>
  );
};

export default CitySelection;
