import { FC, useMemo } from "react";
import { Stack } from "@alexpireddu/map-ui";

import { Slot, SlotTime } from "../../api/queries";

import { SlotsListItem } from "./SlotsListItem";

export type SlotsListProps = {
  slots: Slot[];
};

const charOfSlot = (time: SlotTime) => {
  switch (time) {
    case SlotTime.AFTERNOON: {
      return "P";
    }
    case SlotTime.MORNING: {
      return "M";
    }
    case SlotTime.EVENING: {
      return "S";
    }
  }
}

const dateFormatter = new Intl.DateTimeFormat("it-IT", {
  day: "2-digit",
  month: "short",
  year: "2-digit",
});

const keyOfDates = (left: Date, right: Date) => `${dateFormatter.format(left)} - ${dateFormatter.format(right)}`;

const SlotsList: FC<SlotsListProps> = ({ slots }) => {
  const slotMap = useMemo(() => {
    const sorted = [...slots].sort((a, b) => a.slot_time - b.slot_time);
    return sorted.reduce((map, slot) => {
      const { from_date, to_date, slot_time } = slot;
      const key = keyOfDates(from_date, to_date);
      if (map[key]) {
        map[key].push(charOfSlot(slot_time));
      } else {
        map[key] = [charOfSlot(slot_time)];
      }

      return map;
    }, {} as Record<string, Array<string>>);
  }, [slots]);

  const slotListItems = useMemo(() => {
    return Object.entries(slotMap).map(([key, times]) => (
      <SlotsListItem key={key} dateKey={key} times={times} />
    ));
  }, [slotMap]);

  return (
    <Stack as="span" direction="column" flexWrap="wrap" spacing={1}>
      {slotListItems}
    </Stack>
  );
};

export default SlotsList;
