import { createServer } from "miragejs";

createServer({
  routes() {
    this.post("/v1/login", () => {
      document.cookie = "authorization=aaBBccDD;";

      return true;
    });

    this.namespace = "/v1/subscriber";

    this.get("/info", () => {
      const cookies = document.cookie.split(";");

      const authCookieIndex = cookies.findIndex((el) =>
        el.startsWith("authorization")
      );
      if (authCookieIndex !== -1) {
        return {
          name: "Mario",
          surname: "Rossi",
          email: "mario.rossi@gmail.com",
        };
      }

      return false;
    });

    this.get("/logout", () => {
      const cookies = document.cookie.split(";");

      const authCookieIndex = cookies.findIndex((el) =>
        el.startsWith("authorization")
      );
      if (authCookieIndex !== -1) {
        cookies[authCookieIndex] += ";expires=Thu, 01 Jan 1970 00:00:00 UTC";
      }

      document.cookie = cookies.join(";");

      return true;
    });

    this.namespace = "/v1/consumer";

    this.get("/cities", () => {
      return [
        {
          id: "1",
          name: "Buttapietra, VR",
          sites: [
            {
              id: "1",
              price: 30,
              name: "A",
              latitude: "0",
              longitude: "0",
            },
          ],
        },
        {
          id: "2",
          name: "Zimella, VR",
          sites: [
            {
              id: "1",
              price: 30,
              name: "A",
              latitude: "0",
              longitude: "0",
            },
          ],
        },
        {
          id: "3",
          name: "Verona, VR",
          sites: [
            {
              id: "1",
              price: 30,
              name: "A",
              latitude: "0",
              longitude: "0",
            },
          ],
        },
        {
          id: "4",
          name: "Minerbe, VR",
          sites: [
            {
              id: "1",
              price: 30,
              name: "A",
              latitude: "0",
              longitude: "0",
            },
          ],
        },
      ];
    });

    this.get("/video/campaigns", () => {
      return {
        results: [
          {
            id: "1",
            name: "aaa",
            price: 30,
            state: 210,
            from_date: new Date("02/05/2022"),
            to_date: new Date("03/06/2022"),
            reserved_at: new Date(),
            slots: [
              {
                slot_time: 1,
                from_date: new Date("02/05/2022"),
                to_date: new Date("03/06/2022"),
                site_id: "1",
              },
              {
                slot_time: 2,
                from_date: new Date("02/05/2022"),
                to_date: new Date("03/06/2022"),
                site_id: "1",
              },
              {
                slot_time: 3,
                from_date: new Date("02/05/2022"),
                to_date: new Date("03/06/2022"),
                site_id: "1",
              },
            ],
            image: {
              id: "1",
            },
          },
          {
            id: "2",
            name: "bbb",
            price: 22,
            state: 220,
            from_date: new Date("02/05/2022"),
            to_date: new Date("03/06/2022"),
            reserved_at: new Date(),
            slots: [
              {
                slot_time: 1,
                from_date: new Date("02/05/2022"),
                to_date: new Date("03/06/2022"),
                site_id: "1",
              },
              {
                slot_time: 3,
                from_date: new Date("02/05/2022"),
                to_date: new Date("03/06/2022"),
                site_id: "1",
              },
            ],
            image: {
              id: "2",
            },
          },
        ],
      };
    });

    this.get("/video/campaign/:campaign_id", () => {
      return {
        id: "1",
        name: "aaa",
        price: 30,
        state: 210,
        from_date: new Date("02/05/2022"),
        to_date: new Date("03/06/2022"),
        reserved_at: new Date(),
        slots: [
          {
            slot_time: 1,
            from_date: new Date("02/05/2022"),
            to_date: new Date("03/06/2022"),
            site_id: "1",
          },
          {
            slot_time: 2,
            from_date: new Date("02/05/2022"),
            to_date: new Date("03/06/2022"),
            site_id: "1",
          },
          {
            slot_time: 3,
            from_date: new Date("02/05/2022"),
            to_date: new Date("03/06/2022"),
            site_id: "1",
          },
        ],
        image: {
          id: "1",
        },
      };
    });

    this.get("/video/site/:site_id/available_spaces", () => {
      return {
        from_date: new Date("02/05/2022"),
        to_date: new Date("03/06/2022"),
        slots: [],
      };
    });

    this.get("/city/:city_id/video/sites/online", () => {
      return [
        {
          id: "1",
          price: 30,
          name: "A",
          latitude: "0",
          longitude: "0",
        },
      ];
    });

    this.patch("/video/campaign/:campaign_id/uploadimage", () => {
      return true;
    });

    this.get("/video/campaign/lastimages", () => {
      return [
        {
          id: "1",
        },
      ];
    });
  },
});
