import { ENV_VARIABLES } from "../../infrastructure/environments";

export type Feedback = {
    additional_data?: string;
    city_id?: string;
    feedback: string;
    notes?: string;
    section: string;
}

export const sendFeedback = async (data: Feedback) => fetch(`${ENV_VARIABLES.REACT_APP_BASE_PATH}/consumer/feedbacks`, {
        method: "POST",
        body: JSON.stringify(data),
    });
