import { FC, useEffect, useState } from "react";
import { Button, Stack, Text } from "@alexpireddu/map-ui";
import styled from "@emotion/styled";

import { ImageUpload, ErrorBoundary, FindOutMore } from "../../components";
import { useLastImages, useMediaQuery } from "../../hooks";
import noDefamation from "../../assets/noDefamation.svg";
import noDrugs from "../../assets/noDrugs.svg";
import noEroticism from "../../assets/noEroticism.svg";
import noRacism from "../../assets/noRacism.svg";
import noViolence from "../../assets/noViolence.svg";
import noOpinions from "../../assets/noOpinions.svg";
import graphicGuide from "../../assets/graphic_guide.svg";
import ban from "../../assets/ban.svg";
import { useCreateCampaignContext } from "../../hooks/CreateCampaignManager/CreateCampaignManager";
import { CommandsBar } from "./CommandsBar";
import { useNavigate } from "react-router-dom";

const StyledText = styled(Text)({
  whiteSpace: "nowrap",
});

type SVGImageProps = { width?: string | number; alignSelf?: string };

const SVGImage = styled.img<SVGImageProps>(
  ({ width = "60%", alignSelf = "center" }) => ({
    width,
    alignSelf: alignSelf,
  })
);

const Image = styled("img")({
  aspectRatio: "16 / 9",
  maxWidth: 150,
  minWidth: 150,
  width: 150,
  objectFit: "contain",
  backgroundColor: "#cfd8dc",
  cursor: "pointer",
});

const AlertContainer = styled(Stack)({
  maxWidth: 172,
  border: "2px solid #72c9e5",
  borderRadius: 8,
  background: "#D4F6FF",
  height: "fit-content",
  padding: "24px 16px",
});

const Link = styled.span({
  color: "#025ad5",
  cursor: "pointer",
  fontWeight: "bold",
});

const StyledButton = styled(Button)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: 8,
});

const LastImages: FC<{
  onChange: (image: File) => void;
}> = ({ onChange }) => {
  const imgs = useLastImages().data!;
  const [URIs, setURIs] = useState<string[]>();

  useEffect(() => {
    setURIs(imgs.map((img) => URL.createObjectURL(img)));

    return () => {
      setURIs((URIs) => {
        URIs && URIs.forEach((uri) => URL.revokeObjectURL(uri));
        return undefined;
      });
    };
  }, [imgs]);

  if (!imgs.length) {
    return null;
  }

  return (
    <Stack spacing={1}>
      <StyledText fontSize={16} textAlign="left" fontWeight="bold">
        ULTIMI CARICATI
      </StyledText>
      {URIs?.map((uri, index) => (
        <Image key={uri} src={uri} onClick={() => onChange(imgs[index])} />
      ))}
    </Stack>
  );
};

export const UploadingImage: FC = () => {
  const [openRules, setOpenRules] = useState(false);
  const [openGraphicGuide, setOpenGraphicGuide] = useState(false);

  const matches = useMediaQuery("(min-width: 920px)");

  const {
    campaign: { image, slots },
    site,
    setCampaign,
    cityId,
  } = useCreateCampaignContext();
  const navigate = useNavigate();

  return (
    <>
      <Stack direction="row" spacing={4}>
        <FindOutMore
          variant="rules"
          open={openRules}
          onClose={() => setOpenRules(false)}
        />
        <FindOutMore
          variant="graphic-guide"
          open={openGraphicGuide}
          onClose={() => setOpenGraphicGuide(false)}
        />
        <Stack maxWidth={172} width="100%" spacing={2}>
          <StyledButton
            variant="accent"
            onClick={() => setOpenGraphicGuide(true)}
          >
            <SVGImage src={graphicGuide} width={30} alignSelf={undefined} />
            Guida grafica
          </StyledButton>
          <AlertContainer spacing={2}>
            <SVGImage src={ban} width="35%" />
            <Text
              fontSize="0.875rem"
              color="#60636A"
              lineHeight={1.2}
              display="flex"
              flexWrap="wrap"
            >
              Sono vietati i contenuti con riferimenti a:
              <br />
              <Text as="span" fontWeight="bold">
                violenza
              </Text>
              ,&nbsp;
              <Text as="span" fontWeight="bold">
                razzismo
              </Text>
              ,&nbsp;
              <Text as="span" fontWeight="bold">
                erotismo
              </Text>
              ,&nbsp;
              <Text as="span" fontWeight="bold">
                droghe
              </Text>
              ,&nbsp;
              <Text as="span" fontWeight="bold">
                diffamazioni
              </Text>
              ,&nbsp;
              <Text as="span" fontWeight="bold">
                opinioni
              </Text>
              .
            </Text>
            <Stack direction="row" flexWrap="wrap" spacing={1}>
              <SVGImage width="25%" src={noViolence} />
              <SVGImage width="25%" src={noRacism} />
              <SVGImage width="25%" src={noEroticism} />
              <SVGImage width="25%" src={noDrugs} />
              <SVGImage width="25%" src={noDefamation} />
              <SVGImage width="25%" src={noOpinions} />
            </Stack>
            <Text fontSize="0.875rem" color="#60636A" lineHeight={1.2}>
              Cliccando su continua accetti le{" "}
              <Link onClick={() => setOpenRules(true)}>Condizioni</Link>.
            </Text>
          </AlertContainer>
        </Stack>
        <div
          style={{
            width: "100%",
          }}
        >
          <ImageUpload
            file={image}
            onDropAccepted={(files) => {
              setCampaign({
                image: files[0],
              });
            }}
          />
        </div>
        {matches && (
          <Stack spacing={2} maxWidth={150}>
            <div style={{ minWidth: 150 }}>
              <ErrorBoundary renderError={() => <div />}>
                {<LastImages onChange={(image) => setCampaign({ image })} />}
              </ErrorBoundary>
            </div>
          </Stack>
        )}
      </Stack>
      <CommandsBar
        variant="uploading-image"
        slots={slots.length}
        slotPrice={site.price}
        canGoNext={Boolean(image)}
        onPrev={() => navigate(-1)}
        onNext={() => navigate(`/campaign/create/${cityId}/payment`)}
      />
    </>
  );
}
