import { Button, Stack, Text } from "@alexpireddu/map-ui"
import { FC } from "react"

export interface FeedbackSentProps {
    onClick?: () => void;
    width: number | string;
}

const FeedbackSent: FC<FeedbackSentProps> = ({ onClick, width }) => {
    return (
        <Stack
            spacing={2}
            height={260}
            width={width}
            justifyContent="center"
            paddingBottom={60}>
            <Text as="h3" textAlign="center">Grazie per il tuo contributo!</Text>
            <Button size="sm" variant="secondary" onClick={onClick}>Invia un altro feedback</Button>
        </Stack>
    )
}

export default FeedbackSent