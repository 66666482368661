import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { match } from 'ts-pattern';
import { Billing } from '../../api/mutations';

import { AvailableSlotResponse, Site } from '../../api/queries';
import { useAvailableSlots } from '../useAvailableSlots';
import { useCreateCampaign } from '../useCreateCampaign';
import { useSite } from '../useSite';

export type CreateCampaign = {
  _state: 'initialized' | 'created';
  name: string;
  slots: number[];
  image?: File;
};

export const defaultCreateCampaign: CreateCampaign = {
  _state: 'initialized',
  name: '',
  slots: [],
  image: undefined,
};

export const getSlotsFromIndexes = (
  slots: AvailableSlotResponse,
  slotIndexes: number[],
  siteId: string
) => {
  return slotIndexes.map((slotIndex) => {
    const index = Math.floor(slotIndex / 3);
    const match = slots.find(x => x.from.valueOf() === index)!;

    return {
      site_id: siteId,
      slot_time: match.quantities[slotIndex % 3].slot_time,
      from_date: match.from,
      to_date: match.to,
    };
  });
};

export type UseCreateCampaignState = {
  campaign: Omit<CreateCampaign, '_state'>;
  setCampaign: (newValue: Partial<Omit<CreateCampaign, '_state'>>) => void;
  availableSlots: AvailableSlotResponse;
  pagedSlots: AvailableSlotResponse;
  submit: (billing?: Billing) => void;
  planSubmit: (billing?: Billing) => void;
  site: Site;
  cityId: string;
  prevSlotsPage: () => void;
  nextSlotsPage: () => void;
};

const usePaging = (slots: AvailableSlotResponse, slotPage: number, weekForPage: number) => {
  return slots.slice((slotPage - 1) * weekForPage, slotPage * weekForPage);
}

const WEEKS_FOR_YEAR = 52;
const WEEKS_FOR_PAGE = 5;

export const useCreateCampaignState = (cityId: string): UseCreateCampaignState => {
  const [slotsPage, setSlotsPage] = useState<number>(1);
  const navigate = useNavigate();
  const site = useSite(cityId).data!;
  const slotsData = useAvailableSlots(site.id, WEEKS_FOR_YEAR).data!;
  const mutation = useCreateCampaign();

  const [campaign, setCampaign] = useState({ ...defaultCreateCampaign });
  const [campaignId, setCampaignId] = useState('');

  const pagedSlots = usePaging(slotsData, slotsPage, WEEKS_FOR_PAGE);

  const ctx = useMemo(() => {
    return {
      campaign,
      setCampaign: (newValue: Partial<Omit<CreateCampaign, '_state'>>) => {
        const { image, name, slots } = { ...campaign, ...newValue };
        match(campaign._state)
          .with('initialized', () => {
            mutation.mutate(
              {
                type: 'create',
                city_id: cityId,
                body: {
                  name,
                  slots: getSlotsFromIndexes(slotsData, slots, site.id),
                },
              },
              {
                onSuccess: async (resp) => {
                  const responseBody = await resp.json();
                  setCampaignId(responseBody.campaign_id);
                },
              }
            );
            setCampaign({
              ...campaign,
              _state: 'created',
              ...newValue,
            });
          })
          .with('created', () => {
            mutation.mutate({
              type: 'put',
              body: {
                campaign_id: campaignId,
                image,
                name,
                slots: getSlotsFromIndexes(slotsData, slots, site.id),
              },
            });
            setCampaign({ ...campaign, _state: 'created', ...newValue });
          })
          .exhaustive();
      },
      nextSlotsPage: () => {
        if (slotsPage < (WEEKS_FOR_YEAR / WEEKS_FOR_PAGE)) {
          setSlotsPage(prev => prev + 1);
        }
      },
      prevSlotsPage: () => {
        if (slotsPage !== 1) {
          setSlotsPage(prev => prev - 1);
        }
      },
      availableSlots: slotsData,
      pagedSlots,
      submit: (billing?: Billing) => {
        mutation.mutate({ type: 'pay', campaign_id: campaignId, billing });
      },
      planSubmit: () => {
        mutation.mutate({ type: 'plan', campaign_id: campaignId }, {
          onSuccess: () => {
            navigate(`/campaign/create/${cityId}/created`)
          },
          onError: () => {
            navigate(`/campaign/create/${cityId}/payment-failed`)
          }
        });
      },
      site,
      cityId,
    };
  }, [campaign, slotsData, pagedSlots, site, cityId, mutation, campaignId, slotsPage, navigate]);

  return ctx;
};
