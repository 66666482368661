import { DropDown, Stack, Text } from "@alexpireddu/map-ui";
import { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";

import { useAuthenticated, useLogout } from "../../hooks";
import { Avatar } from "../Avatar";

const Menu = styled("div")({
  padding: 24,
  boxShadow: "0 16px 32px 0 rgba(2, 3, 3, 0.16);",
  borderRadius: 12,
  backgroundColor: "#fff",
  width: 312,
});

const Divider = styled("div")({
  height: 0.5,
  backgroundColor: "#cfd8dc",
  margin: "0px -24px",
});

const TextContainer = styled("div")({
  textAlign: "center",
  marginTop: 8,
  marginBottom: 16,
});

const UserContainer = styled("div")({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const HistoryLink = styled(Link)({
  fontSize: "0.75rem",
  fontWeight: 600,
  lineHeight: 1.5,
  border: "1px solid #263238",
  color: "#263238",
  width: "100%",
  borderRadius: 8,
  padding: 8,
  backgroundColor: "#fff",
  display: "flex",
  justifyContent: "center",
  "&:hover, &:focus": {
    backgroundColor: "#263238",
    color: "#fff",
  },
  whiteSpace: "nowrap",
});

const StyledLink = styled(Link)({
  fontSize: "0.875rem",
  lineHeight: 1.4,
  color: "#263238",
  padding: "8px 24px",
  margin: "0px -24px",
  "&:hover, &:focus": {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
});

const LogoutLink = styled(StyledLink)({
  color: "#be0000",
  "&:hover, &:focus": {
    backgroundColor: "rgba(190, 0, 0, 0.1)",
  },
});

export type UserMenuProps = {
  open: boolean;
  onClick: () => void;
  onClickOutside: () => void;
  onOpenFeedbackModal: () => void;
};

const UserMenu: FC<UserMenuProps> = ({
  open = false,
  onClick,
  onClickOutside,
  onOpenFeedbackModal,
}) => {
  const {
    user: { name, surname, email },
  } = useAuthenticated().data!;
  const navigate = useNavigate();
  const handleLogout = useLogout();

  const avatarText = name[0] + surname[0];

  return (
    <DropDown
      open={open}
      content={
        <Menu>
          <Stack spacing={3}>
            <UserContainer>
              <Avatar size="large" name={avatarText} variant="light" />
              <TextContainer>
                <Text fontWeight={600}>{`${name} ${surname}`}</Text>
                <Text as="p">{email}</Text>
              </TextContainer>
              <HistoryLink onClick={onClick} to="/advertising-history">
                LE MIE PRENOTAZIONI
              </HistoryLink>
            </UserContainer>
            <Divider />
            <Stack>
              <StyledLink onClick={onClick} to="/support">
                Assistenza
              </StyledLink>
              <StyledLink
                to="#"
                onClick={() => {
                  onOpenFeedbackModal();
                  onClick();
                }}
              >
                Lascia un feedback
              </StyledLink>
              <LogoutLink
                to="#"
                onClick={async () => {
                  await handleLogout();
                  navigate("/");
                }}
              >
                Esci
              </LogoutLink>
            </Stack>
          </Stack>
        </Menu>
      }
      trigger={<Avatar name={avatarText} variant="light" />}
      onClick={onClick}
      onClickOutside={onClickOutside}
    />
  );
};

export default UserMenu;
