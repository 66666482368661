import { useState, useCallback } from "react";
import { SortBy } from "../../api/queries";

type UseOrderState = {
  sort_by: SortBy;
  sort_mode: "asc" | "desc";
};

export const useOrder = () => {
  const [order, setOrder] = useState<UseOrderState | undefined>({
    sort_by: "reserved_at",
    sort_mode: "desc",
  });
  const handleOrderChange = useCallback((sort_by: SortBy) => {
    setOrder((prev) => {
      if (!prev || prev.sort_by !== sort_by) {
        return {
          sort_by,
          sort_mode: "asc",
        };
      }

      if (prev.sort_mode === "asc") {
        return {
          sort_by,
          sort_mode: "desc",
        };
      }

      return {
        sort_by,
        sort_mode: "asc",
      }
    });
  }, []);

  return [order, handleOrderChange] as const;
}
