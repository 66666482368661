import { createContext, FC, PropsWithChildren, useContext } from "react";
import {
  defaultCreateCampaign,
  UseCreateCampaignState,
  useCreateCampaignState,
} from "./useCreateCampaignState";
import { useRedirectOnMount } from "./useRedirectOnMount";
import { useCityParams } from "../useCityParams";

const CreateCampaignContext = createContext<UseCreateCampaignState>({
  campaign: { ...defaultCreateCampaign },
  availableSlots: [],
  pagedSlots: [],
  setCampaign: () => console.error("CreateCampaignContext not initialized"),
  submit: () => console.error("CreateCampaignContext not initialized"),
  planSubmit: () => console.error("CreateCampaignContext not initialized"),
  site: undefined as any,
  cityId: "",
  nextSlotsPage: () => console.error("CreateCampaignContext not initialized"),
  prevSlotsPage: () => console.error("CreateCampaignContext not initialized"),
});

export const CreateCampaignManager: FC<PropsWithChildren> = ({ children }) => {
  const cityId = useCityParams().cityId!;

  const ctx = useCreateCampaignState(cityId);

  useRedirectOnMount("general-information");

  return (
    <CreateCampaignContext.Provider value={ctx}>
      {children}
    </CreateCampaignContext.Provider>
  );
}

export const useCreateCampaignContext = () => useContext(CreateCampaignContext);
