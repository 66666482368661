import { z } from "zod";

import { DateSchema } from "./DateSchema";

export enum SlotTime {
  MORNING = 1,
  AFTERNOON,
  EVENING,
}

export const Slot = z.object({
  slot_time: z.nativeEnum(SlotTime),
  from_date: DateSchema,
  to_date: DateSchema,
  site_id: z.string(),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Slot = z.TypeOf<typeof Slot>