import { Outlet, RouteObject } from "react-router-dom";
import CreateCampaignLayout from "../components/CreateCampaignLayout";
import { CreateCampaignManager } from "../hooks";
import {
  CitySelection,
  GeneralInformation,
  PaymentFailed,
  Recap,
  UploadingImage,
} from "../pages";
import { CampaignCreated } from "../pages";
import { NotFoundErrorPage } from "../components";

export const campaignCreation: RouteObject = {
  path: "campaign/create",
  children: [
    {
      index: true,
      element: <CitySelection />,
    },
    {
      path: ":cityId/created",
      element: <CampaignCreated />,
    },
    {
      path: ":cityId/payment-failed",
      element: <PaymentFailed />,
    },
    {
      path: ":cityId",
      element: (
        <CreateCampaignLayout>
          <CreateCampaignManager>
            <Outlet />
          </CreateCampaignManager>
        </CreateCampaignLayout>
      ),
      children: [
        {
          path: "general-information",
          element: <GeneralInformation />,
        },
        {
          path: "uploading-image",
          element: <UploadingImage />,
        },
        {
          path: "payment",
          element: <Recap />,
        },
      ],
    },
    {
      path: '*',
      element: <NotFoundErrorPage />,
    },
  ],
};
