import { FC } from "react";
import styled from "@emotion/styled";
import { Stack, Text, Tooltip } from "@alexpireddu/map-ui";
import { match } from "ts-pattern";

const Rect = styled("span")({
  borderRadius: 4,
  color: "white",
  backgroundColor: "#263238",
  height: 24,
  width: 24,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const TooltipContent = styled.div({
  padding: 4,
  display: "flex",
  gap: 4,
});

export type SlotsListItemProps = {
  dateKey: string;
  times: string[];
};

export const SlotsListItem: FC<SlotsListItemProps> = ({ dateKey, times }) => {
  const renderTooltip = (time: string) => {
    const { title, times } = match(time)
      .with("M", () => ({ title: "Mattina", times: "06:00-12:00" }))
      .with("P", () => ({ title: "Pomeriggio", times: "12:00-18:00" }))
      .otherwise(() => ({ title: "Sera", times: "18:00-24:00" }));

    return (
      <TooltipContent>
        <Text fontSize="0.875rem" fontWeight="bold">
          {title}
        </Text>
        <Text fontSize="0.875rem">{times}</Text>
      </TooltipContent>
    );
  };

  return (
    <Stack as="span" direction="row" spacing={1} alignItems="center">
      {dateKey}
      {times.map((time, index) => (
        <Tooltip key={index} position="top" content={renderTooltip(time)}>
          <Rect>{time}</Rect>
        </Tooltip>
      ))}
    </Stack>
  );
};
