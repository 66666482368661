import { FC, useState, useEffect, useMemo } from "react";
import { Text, Stack, Button } from "@alexpireddu/map-ui";
import styled from "@emotion/styled";

import { MainPage, FindOutMore } from "../../components";
import { useMediaQuery } from "../../hooks";

const Divider = styled.hr({
  width: 3,
  height: 48,
  backgroundColor: "black",
  alignSelf: "start",
  marginTop: 8,
});

const getCountdown = (date: Date) => {
  const distance = date > new Date() ? date.valueOf() - Date.now() : 0;
  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
}

const dateFormatter = new Intl.DateTimeFormat("it-IT", {
  month: "long",
  day: "numeric",
  weekday: "long",
});

export type CountdownProps = {
  date: Date;
};

const Countdown: FC<CountdownProps> = ({ date }) => {
  const [countdown, setCountdown] = useState(getCountdown(date));
  const [openModal, setOpenModal] = useState(false);
  const dateFormatted = useMemo(() => {
    return dateFormatter.format(date);
  }, [date]);
  const matches = useMediaQuery("(min-width: 1080px)");

  useEffect(() => {
    setCountdown(getCountdown(date));
    const id = setInterval(() => {
      setCountdown(getCountdown(date));
    }, 1000);

    return () => {
      clearInterval(id);
    };
  }, [date]);

  return (
    <MainPage variant="main">
      <FindOutMore open={openModal} onClose={() => setOpenModal(false)} />
      <Stack
        alignItems="center"
        justifyContent="center"
        spacing={4}
        width={!matches ? 330 : 388}
        height={274}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <Stack alignItems="center" spacing={1}>
            <Text as="span" fontWeight="bold" fontSize={matches ? "3.3rem" : "2.8rem"} lineHeight={1}>
              -{countdown[0].toString().padStart(2, "0")}
            </Text>
            <Text textAlign="center" fontSize={"0.875rem"}>
              GIORNI
            </Text>
          </Stack>
          <Divider />
          <Stack alignItems="center" spacing={1}>
            <Text as="span" fontWeight="bold" fontSize={matches ? "3.3rem" : "2.8rem"} lineHeight={1}>
              {countdown[1].toString().padStart(2, "0")}
            </Text>
            <Text textAlign="center" fontSize={"0.875rem"}>
              ORE
            </Text>
          </Stack>
          <Divider />
          <Stack alignItems="center" spacing={1}>
            <Text as="span" fontWeight="bold" fontSize={matches ? "3.3rem" : "2.8rem"} lineHeight={1}>
              {countdown[2].toString().padStart(2, "0")}
            </Text>
            <Text textAlign="center" fontSize={"0.875rem"}>
              MINUTI
            </Text>
          </Stack>
          <Divider />
          <Stack alignItems="center" spacing={1}>
            <Text as="span" fontWeight="bold" fontSize={matches ? "3.3rem" : "2.8rem"} lineHeight={1}>
              {countdown[3].toString().padStart(2, "0")}
            </Text>
            <Text textAlign="center" fontSize={"0.875rem"}>
              SECONDI
            </Text>
          </Stack>
        </Stack>
        <Text lineHeight={1.25}>
          Gli ordini apriranno{" "}
          <Text as="span" fontWeight="bold" textTransform="capitalize">
            {dateFormatted}
          </Text>
          <br />
          nell'impianto #1 di Isola della Scala (VR)
        </Text>
        <Button onClick={() => setOpenModal(true)} variant="accent">
          SCOPRI DI PIÙ
        </Button>
      </Stack>
    </MainPage>
  );
};

export default Countdown;
