import { FC, PropsWithChildren, useState } from "react";
import styled from "@emotion/styled";
import { Card } from "@alexpireddu/map-ui";
import { Container, Text, Stack } from "@alexpireddu/map-ui";

import { useMediaQuery } from "../../hooks";
import {
  CommonLayout,
  CommonLayoutProps,
  FindOutMore,
  InstallationsMap,
  PrivacyPolicyLink,
} from "../";
import city_selection from "../../assets/sfondo_home.jpg";
import city_selection_mobile from "../../assets/sfondo_home_mobile_XXXL.jpg";
import logo_ministero from "../../assets/LOGO_MINISTERO.svg";
import log_d from "../../assets/LOGO_D.svg";
import logo_invitalia from "../../assets/LOGO_INVITALIA.svg";

const LinkModal = styled.p({
  fontStyle: "normal",
  color: "#025ad5",
  textDecoration: "underline",
  textAlign: "right",
  cursor: "pointer",
});

const Background = styled("div")({
  backgroundColor: "#edfbfe",
  "@media (min-width: 320px)": {
    backgroundImage: `url(${city_selection_mobile})`,
    backgroundSize: "100%",
    backgroundPosition: "left bottom",
    backgroundRepeat: "no-repeat",
  },
  "@media (min-width: 641px)": {
    backgroundImage: `url(${city_selection})`,
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
  },
  backgroundPosition: "center",
  position: "relative",
});

const StyledCard = styled(Card)({
  maxWidth: 700,
  width: "fit-content",
  boxShadow: "0 8px 32px 0 rgba(23, 148, 202, 0.26);",
});

const FooterContainer = styled.div({
  position: "absolute",
  bottom: 0,
  right: 0,
  marginTop: 8,
  marginBottom: 8,
  marginRight: 8,
});


export type MainPageProps = PropsWithChildren<{
  variant?: CommonLayoutProps["variant"];
  hasFindOutMore?: boolean;
  disableAlert?: boolean;
}>;

const MainPage: FC<MainPageProps> = ({
  children,
  variant = "campaign-creation",
  hasFindOutMore,
  disableAlert = false,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const matches = useMediaQuery("(min-width: 1020px)");

  return (
    <>
      <Background>
        {matches && (
          <MainPageAlert
            show={variant !== "campaign-creation" && !disableAlert}
          />
        )}
        <FindOutMore open={openModal} onClose={() => setOpenModal(false)} />
        <CommonLayout variant={variant}>
          <Container maxWidth="md" disableXGutters>
            <Stack spacing={6} alignItems="center">
              <div>
                <Text as="h1" textAlign="center" lineHeight={1.73}>
                  Benvenuto in Publista
                </Text>
                <Text
                  as="h3"
                  fontWeight="normal"
                  textAlign="center"
                  lineHeight={1.59}
                >
                  La piattaforma per la prenotazione delle pubbliche affissioni
                  digitali
                  {hasFindOutMore && (
                    <LinkModal onClick={() => setOpenModal(true)}>
                      Scopri di più
                    </LinkModal>
                  )}
                </Text>
              </div>
              <StyledCard>{children}</StyledCard>
            </Stack>
          </Container>
        </CommonLayout>
      </Background>
      <FooterContainer>
        <PrivacyPolicyLink />
      </FooterContainer>
    </>
  );
};

const MainPageAlertRoot = styled.div({
  padding: 24,
  position: "absolute",
  top: 24,
  right: 24,
  backgroundColor: "rgb(0, 102, 204)",
  color: "white",
  maxWidth: 250,
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  gap: 20,
  width: "100%",
});

const Button = styled.button({
  padding: "8px 12px",
  borderRadius: 8,
  border: "2px solid white",
  backgroundColor: "transparent",
  color: "white",
  fontSize: 14,
  cursor: "pointer",
});

type SVGImageProps = { width?: string };

const SVGImage = styled.img<SVGImageProps>(({ width = "36px" }) => ({
  width,
}));

type MainPageAlerProps = {
  show?: boolean;
};

const MainPageAlert: FC<MainPageAlerProps> = ({ show }) => {
  const [open, setOpen] = useState(false);

  if (!show) return null;

  return (
    <>
      <InstallationsMap
        open={open}
        position={[45.275578, 11.007682]}
        onClose={() => setOpen(false)}
      />
      <MainPageAlertRoot>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <SVGImage src={logo_ministero} 
                      style={{
                        marginLeft: 12,
                        marginRight: 16,
                      }}
          />
          <SVGImage src={logo_invitalia} width="56px" />
        </div>
        <Text fontWeight="bold">SERVIZIO SPERIMENTALE</Text>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 14,
          }}
        >
          <Text fontSize={13} lineHeight={1.3}>
            Il servizio per ora è disponibile solo nel comune di Isola della
            Scala (VR).
          </Text>
          <Button onClick={() => setOpen(true)}>VEDI POSIZIONE IMPIANTO</Button>
        </div>
        <Text fontSize={13} lineHeight={1.3} fontWeight="bold">
          Presto saranno disponibili tanti altri comuni!
        </Text>
      </MainPageAlertRoot>
    </>
  );
};

export default MainPage;
