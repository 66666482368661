import { Flex, Modal, ModalBody, ModalCloseButton, ModalHeader } from '@alexpireddu/map-ui';
import { FC } from 'react';
import { sendFeedback } from '../../api/mutations';
import { FeedbackControl } from '.';

export type LeaveFeedbackModalProps = {
    open: boolean;
    cityId?: string;
    onClose: () => void;
}

const LeaveFeedbackModal: FC<LeaveFeedbackModalProps> = ({ open, onClose, cityId }) => {
    if (!open) {
        return null;
    }

    return <Modal>
        <ModalHeader>La tua opinione è importante!</ModalHeader>
        <ModalCloseButton onClick={onClose} />
        <ModalBody>
            <Flex justifyContent={"center"} marginBottom="1rem">
                <FeedbackControl
                    cityId={cityId}
                    section="FREE_FEEDBACK"
                    sendFeedbackFn={sendFeedback}
                />
            </Flex>
        </ModalBody>
    </Modal>
}

export default LeaveFeedbackModal;