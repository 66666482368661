import { Stack, Text, Toggle, Card } from "@alexpireddu/map-ui";
import styled from "@emotion/styled";
import { Control, useController } from "react-hook-form";
import { z } from "zod";

import { ControlledInput } from "../../components";
import { BillingSchema as BillingBaseSchema } from "../../api/mutations";
import { useProvincesQuery } from "../../hooks";
import { FC, useMemo } from "react";

export const BillingSchema = z
  .discriminatedUnion("withBilling", [
    z.object({ withBilling: z.literal(false) }),
    BillingBaseSchema.extend({ withBilling: z.literal(true) }),
  ])
  .transform((val) => ({
    ...val,
    ...(val.withBilling && {
      vat_number: val.vat_number.match(/^(IT|it)/)
        ? val.vat_number
        : `IT${val.vat_number}`,
    }),
  }));

export type Billing = z.TypeOf<typeof BillingSchema>;

const OptionalInput = styled(ControlledInput<Billing>)({
  width: "30%",
});

const Paper = styled.div({
  borderRadius: 12,
  padding: 24,
  border: "1px solid #cfd8dc",
  backgroundColor: "rgba(236, 239, 241, 0.5)",
});

const StyledCap = styled(ControlledInput<Billing>)({
  input: {
    maxWidth: 100,
  },
});

type BillingFormProps = {
  control: Control<Billing>;
  disabled?: boolean;
};

export const BillingForm: FC<BillingFormProps> = ({ control, disabled }) => {
  const {
    field: { value: checked, onChange },
  } = useController({
    control,
    name: "withBilling",
  });

  const data = useProvincesQuery().data!;
  const options = useMemo(() => {
    return data.map((el) => ({
      value: el.code,
      label: el.name,
    }));
  }, [data]);

  const Root = checked ? Card : Paper;

  return (
    <Root
      style={{
        ...(disabled && { opacity: 0.5 }),
      }}
      size="lg"
    >
      <Stack spacing={4}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text as="h3">Voglio la fattura</Text>
          <Toggle
            disabled={disabled}
            checked={checked}
            onChange={() => {
              onChange(!checked);
            }}
          />
        </Stack>
        {checked && (
          <>
            <Stack direction="column" flexWrap="wrap" spacing={2}>
              <Stack direction="row" spacing={2}>
                <ControlledInput
                  fullWidth
                  variant="text"
                  control={control}
                  name="name"
                  label="Ragione sociale*"
                />
                <ControlledInput
                  fullWidth
                  variant="text"
                  control={control}
                  name="vat_number"
                  label="P.IVA*"
                  transform={{
                    output: (e) => e.target.value.toUpperCase(),
                  }}
                />
                <ControlledInput
                  fullWidth
                  variant="text"
                  control={control}
                  name="fiscal_code"
                  label="Codice Fiscale*"
                  transform={{
                    output: (e) => e.target.value.toUpperCase(),
                  }}
                />
              </Stack>
              <Stack direction="row" spacing={2}>
                <ControlledInput
                  fullWidth
                  variant="text"
                  control={control}
                  name="address"
                  label="Indirizzo*"
                />
                <StyledCap
                  variant="text"
                  control={control}
                  name="postal_code"
                  label="CAP*"
                />
                <ControlledInput
                  fullWidth
                  variant="text"
                  control={control}
                  name="city"
                  label="Comune*"
                />
                <ControlledInput
                  fullWidth
                  variant="select"
                  control={control}
                  name="province"
                  label="Provincia*"
                  options={options}
                  isSearchable
                />
              </Stack>
            </Stack>
            <Stack spacing={2.5}>
              <Text as="h3">Modalità di consegna (opzionale)</Text>
              <Stack direction="row" spacing={2}>
                <OptionalInput
                  variant="text"
                  control={control}
                  name="sdi_code"
                  label="Codice Destinatario SDI"
                />
                <OptionalInput
                  variant="text"
                  control={control}
                  name="pec"
                  label="PEC Destinatario"
                />
              </Stack>
            </Stack>
          </>
        )}
      </Stack>
    </Root>
  );
}
