import * as Sentry from '@sentry/react';
import { isDevMode, isTestMode } from './environments';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  createBrowserRouter,
} from 'react-router-dom';
import { useEffect } from 'react';

const SENTRY_API_KEY = "https://a808683686b841ee99dfb73bfef7cf7f@o749218.ingest.sentry.io/4504100446732288";

export const initializeSentry = () => {
  if (!isDevMode()) {
    Sentry.init({
      dsn: SENTRY_API_KEY,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
          ),
        }),
        new Sentry.Replay(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      environment: isTestMode() ? 'test' : 'production',
    });
  }
};

export const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const configureSentryUser = (
  id: string,
  email: string,
  name: string,
  surname?: string
) => {
  Sentry.setUser({ email: email, id: id, username: `${name} ${surname} ` });
};

export const resetSentryUser = () => {
  Sentry.setUser(null);
};
