import { Outlet } from "react-router-dom";
import { useMediaQuery } from "../hooks";
import { Mobile } from "../pages";
import { FC } from "react";

export const MobileGuard: FC = () => {
  const matches = useMediaQuery("(min-width: 720px)");
  if (!matches) {
    return <Mobile />;
  }

  return <Outlet />;
}
