import { Stack } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import { FC, useState } from 'react'
import { DissatisfiedSentimentIcon, NeutralSentimentIcon, SatisfiedSentimentIcon } from './Icons';

export type Sentiment = "NONE" | "DISSATISFIED" | "NEUTRAL" | "SATISFIED";

const SentimentTile = styled.div({
    cursor: "pointer",
})

export interface SentimentsPanelProps {
    onSelect?: (arg: Sentiment) => void;
    disabled?: boolean;
}

const SentimentsPanel: FC<SentimentsPanelProps> = ({ onSelect, disabled = false }) => {
    const [sentiment, setSentiment] = useState<Sentiment>("NONE");

    const handleSelection = (s: Sentiment) => {
        if (!disabled) {
            setSentiment(s);

            if (typeof onSelect === "function") {
                onSelect(s)
            }
        }
    }

    return <Stack spacing={2} direction="row" justifyContent="center">
        <SentimentTile onClick={() => handleSelection("DISSATISFIED")}>
            <DissatisfiedSentimentIcon active={sentiment === "DISSATISFIED"} />
        </SentimentTile>
        <SentimentTile onClick={() => handleSelection("NEUTRAL")}>
            <NeutralSentimentIcon active={sentiment === "NEUTRAL"} />
        </SentimentTile>
        <SentimentTile onClick={() => handleSelection("SATISFIED")}>
            <SatisfiedSentimentIcon active={sentiment === "SATISFIED"} />
        </SentimentTile>
    </Stack>
}

export default SentimentsPanel;