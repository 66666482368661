import { FC, PropsWithChildren, ReactNode } from "react";
import styled from "@emotion/styled";

const Root = styled("span")({
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const BadgeData = styled("span")({
  position: "absolute",
  top: 0,
  right: 0,
  transform: "scale(1) translate(50%, -50%)",
  transformOrigin: "100% 0%",
  background:
    "linear-gradient(45deg, rgba(255,26,144,1) 0%, rgba(0,187,250,1) 100%)",
  borderRadius: "50%",
  color: "white",
  width: 20,
  height: 20,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "0.75rem",
});

export type BadgeProps = PropsWithChildren<{
  content?: ReactNode;
}>;

const Badge: FC<BadgeProps> = ({ children, content }) => {
  return (
    <Root>
      {children}
      {content && <BadgeData>{content}</BadgeData>}
    </Root>
  );
};

export default Badge;
