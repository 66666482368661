import { useEffect, useState } from 'react';
import { Box, Radio, Stack, Text } from '@alexpireddu/map-ui';
import { Card } from '@alexpireddu/map-ui';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import { zodResolver } from '@hookform/resolvers/zod';

import {
  useSite,
  useAuthenticated,
  useCities,
  useCityParams,
  useCampaignPaymentTypeQuery,
} from '../../hooks';
import { CampaignCard, Tooltip } from '../../components';
import { useCreateCampaignContext } from '../../hooks/CreateCampaignManager/CreateCampaignManager';
import { CommandsBar } from './CommandsBar';
import { BillingForm, Billing, BillingSchema } from './BillingForm';
import { getSlotsFromIndexes } from "../../hooks/CreateCampaignManager/useCreateCampaignState";

const SpaceBetweenText = styled(Text)({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '1px 0',
});

const BottomBorderText = styled(SpaceBetweenText)({
  borderBottom: '0.5px solid #cfd8dc',
});

const currencyFormat = new Intl.NumberFormat(navigator.language, {
  style: 'currency',
  currency: 'EUR',
});

const format = (value: number) => {
  const currencyStr = currencyFormat.format(value);

  return currencyStr.split(' ').reverse().join(' ');
};

const COMMISSIONS = 2;

const VAT_MUL = 1.22;

const VAT = 22;

const totalPrice = (slotPrice: number, slots: number) =>
  slotPrice * slots + COMMISSIONS;

const computeVATOnPrice = (slotPrice: number) => {
  const noVat = slotPrice / VAT_MUL;

  return Number((slotPrice - noVat).toFixed(2));
};

export const Recap = () => {
  const cityId = useCityParams().cityId!;
  const navigate = useNavigate();

  const { control, formState, handleSubmit, setValue } = useForm<Billing>({
    resolver: zodResolver(BillingSchema),
    mode: 'onBlur',
    defaultValues: {
      withBilling: false,
    },
  });
  const { isValid } = formState;

  const [paymentType, setPaymentType] = useState('stripe');
  const handlePaymentTypeChange = (paymentType: string) => {
    setPaymentType(paymentType);
    setValue('withBilling', false);
  };
  const billingShouldBeDisabled = paymentType === 'external';

  const [URI, setURI] = useState('');

  const {
    campaign: information,
    submit,
    planSubmit,
    availableSlots } = useCreateCampaignContext();
  const { image } = information;

  const cities = useCities().data!;
  const city = cities.find((el) => el.id);

  const { user } = useAuthenticated().data!;
  const site = useSite(cityId).data!;
  const slots = getSlotsFromIndexes(availableSlots, information.slots, site.id);

  useEffect(() => {
    if (image) {
      setURI(URL.createObjectURL(image));
    }

    return () => {
      setURI((last) => {
        URL.revokeObjectURL(last);
        return '';
      });
    };
  }, [image]);
  const type = useCampaignPaymentTypeQuery().data!;

  return (
    <>
      {type === 'BUSINESS' && (
        <Box marginBottom={40}>
          <PaymentType
            paymentType={paymentType}
            onPaymentTypeChange={handlePaymentTypeChange}
          />
        </Box>
      )}
      <Stack spacing={2}>
        <Text
          as="h2"
          display="flex"
          justifyContent="space-between"
          paddingRight={24}
        >
          Totale da pagare{' '}
          <Text as="span">
            {format(totalPrice(site.price, information.slots.length))}
          </Text>
        </Text>
        <BillingForm disabled={billingShouldBeDisabled} control={control} />
        <Card size="lg">
          <Stack spacing={2}>
            <Text as="h3" fontWeight="bold">
              Prenotazione campagna
            </Text>
            <Stack spacing={3}>
              <CampaignCard
                user={user}
                name={information.name}
                slots={slots}
                imgSrc={URI}
              />
              <div>
                <BottomBorderText>
                  Tariffa spazio {city?.name}, {city?.province.code}
                  <Text as="span" fontWeight="bold">
                    {format(site.price)}
                  </Text>
                </BottomBorderText>
                <BottomBorderText>
                  <span />
                  <Text as="span" color="#b1b1b1">
                    {`Di cui IVA (${VAT}%) ${format(
                      computeVATOnPrice(site.price)
                    )}`}
                  </Text>
                </BottomBorderText>
                <BottomBorderText>
                  Quantità spazi
                  <Text as="span" fontWeight="bold">
                    {information.slots.length}
                  </Text>
                </BottomBorderText>
                <BottomBorderText>
                  <Text as="span" fontWeight="bold">
                    Sub-totale
                  </Text>
                  <Text as="span" fontWeight="bold">
                    {format(site.price * information.slots.length)}
                  </Text>
                </BottomBorderText>
                <BottomBorderText>
                  <Tooltip
                    content={
                      <Text as="span" padding={8} fontSize="0.875rem">
                        Commissione che Publista deve riconoscere allo strumento
                        di pagamento
                      </Text>
                    }
                  >
                    Commissioni pagamento
                  </Tooltip>
                  <Text as="span" fontWeight="bold">
                    {format(COMMISSIONS)}
                  </Text>
                </BottomBorderText>
              </div>
              <SpaceBetweenText as="h3" fontWeight="bold">
                Totale
                <span>
                  {format(totalPrice(site.price, information.slots.length))}
                </span>
              </SpaceBetweenText>
            </Stack>
          </Stack>
        </Card>
      </Stack>
      <CommandsBar
        variant="payment"
        slots={slots.length}
        slotPrice={site.price}
        canGoNext={isValid}
        onPrev={() => navigate(-1)}
        onNext={handleSubmit((val) => {
          if (paymentType === 'external') {
            planSubmit();
            return;
          }
          if (val.withBilling) {
            const { withBilling, ...rest } = val;
            submit(rest);
          } else {
            submit();
          }
        })}
      />
    </>
  );
};

type PaymentTypeProps = {
  paymentType: string;
  onPaymentTypeChange: (paymentType: string) => void;
};

const PaymentTypeRadioCard = styled(Card)({
  display: 'flex',
  justifyContent: 'space-between',
  flex: 1,
});

const StyledRadio = styled(Radio)({
  transform: 'translateX(42px)',
  'div.__mapui_radioControl::after': {
    left: 3.5,
    top: 3.5,
  },
});

const PaymentType = ({
  paymentType,
  onPaymentTypeChange,
}: PaymentTypeProps) => {
  return (
    <Stack direction="row" spacing={2}>
      <PaymentTypeRadioCard size="xl">
        <Text as="h2">Paga ora</Text>
        <StyledRadio
          name="payment-type"
          value="stripe"
          checked={paymentType === 'stripe'}
          onChange={(e) => onPaymentTypeChange(e.target.value)}
        />
      </PaymentTypeRadioCard>
      <PaymentTypeRadioCard size="xl">
        <Text as="h2">Paga dopo</Text>
        <StyledRadio
          name="payment-type"
          value="external"
          checked={paymentType === 'external'}
          onChange={(e) => onPaymentTypeChange(e.target.value)}
        />
      </PaymentTypeRadioCard>
    </Stack>
  );
};
