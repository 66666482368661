import { z } from "zod";
import { configureSentryUser } from "../../infrastructure/sentryConfigurations";
import { ENV_VARIABLES } from "../../infrastructure/environments";

const User = z
  .object({
    id: z.string(),
    name: z.string(),
    surname: z.string(),
    email: z.string(),
    fiscal_number: z.string(),
  })
  .transform(({ fiscal_number, ...rest }) => ({
    ...rest,
    fiscalNumber: fiscal_number,
  }));

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type User = z.TypeOf<typeof User>;

export const getProfile = async () => {
  const response = await fetch(
    `${ENV_VARIABLES.REACT_APP_BASE_PATH}/subscriber/info`
  );

  const user = User.parse(await response.json());

  const { id, email, name, surname } = user;
  configureSentryUser(id, email, name, surname);

  return user;
}
