import { FC, CSSProperties, ComponentProps } from "react";
import styled from "@emotion/styled";

type TableCellProps = Pick<CSSProperties, "textAlign" | "width"> & {
  variant: "td" | "th";
};

const tableCellVariant = {
  td: {
    color: "#263238",
  },
  th: {
    backgroundColor: "#263238",
    color: "#fff",
  },
};

const shouldNotForward = ["variant", "textAlign", "as", "width"];

const TableCellRoot = styled("td", {
  shouldForwardProp: (propKey) =>
    !shouldNotForward.find((el) => propKey === el),
})<TableCellProps>(({ textAlign = "center", variant, width }) => ({
  ...tableCellVariant[variant],
  fontSize: "0.875rem",
  fontWeight: "normal",
  paddingTop: "12px",
  paddingBottom: "12px",
  textAlign,
  width
}));

type TableCellRootProps = ComponentProps<typeof TableCellRoot>;

export const TableCell: FC<TableCellRootProps> = ({ variant, ...rest }) => {
  return <TableCellRoot as={variant} variant={variant} {...rest} />;
};
